import { View, StyleSheet } from '@react-pdf/renderer'
import { IHeader } from '../../interfaces'
import LeftContainer from './LeftContainer'
import RightContainer from './RightContainer'

//COMPONENT
export default function HeaderBlack({
  fullName,
  email,
  phone,
  address,
  website,
  position
}: IHeader) {
  const styles = StyleSheet.create({
    container: {
      backgroundColor: '#161623',
      color: '#ffffff',
      marginTop: -36,
      paddingLeft: 36,
      paddingRight: 36
    },
    mainContent: {
      display: 'flex',
      flexDirection: 'row'
    },
    summaryBox: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 16
    },
    summary: {
      fontSize: 12,
      fontWeight: 400,
      textAlign: 'left',
      marginBottom: 22
    }
  })
  return (
    <View style={styles.container}>
      <View style={styles.mainContent}>
        <LeftContainer {...{ fullName, position }} />
        <RightContainer {...{ email, phone, address, website }} />
      </View>
    </View>
  )
}
