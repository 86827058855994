export interface IGame {
  currentDirection: EDirections
  start(): void
  stop(): void
  handleSkipBoard(): void
  handleSkipSnake(): void
  isEven(num: number): boolean
  clear(): void
  animation(callback: FrameRequestCallback): void
  keyDownEvents(e: KeyboardEvent): void
}

export interface IGameObject {
  setProgress(progress: number): void
  setSnakeProgress(progress: number): void
  clear(): void
  stop(): void
  animation(callback: FrameRequestCallback): void
  ctx: CanvasRenderingContext2D

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  boardService: any
}

export interface IGameText {
  message: string
  x: number
  y: number
}

export interface ICell {
  x: number
  y: number
  cellSize: number
}

export enum EDirections {
  Up = 'ArrowUp',
  Right = 'ArrowRight',
  Down = 'ArrowDown',
  Left = 'ArrowLeft',
  Skip = 'Skip'
}

export enum EOppositeDirections {
  ArrowUp = 'ArrowDown',
  ArrowDown = 'ArrowUp',
  ArrowLeft = 'ArrowRight',
  ArrowRight = 'ArrowLeft'
}

export enum EKeyboard {
  Space = 'Space'
}
export type ISnakeDirection = EDirections | EOppositeDirections

export interface ISnakePart {
  x: number
  y: number
  direction: ISnakeDirection
}

export interface ISnake {
  body: ISnakePart[]
  velocity: number
}

export interface IFruit {
  x: number
  y: number
}
