import c from 'clsx'
import { SVGProps } from 'react'

export default function Check({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="check"
      fill="white"
      className={c(['fill-current'], [className && className])}
      {...props}
    >
      <path d="M9.00016 16.1698L4.83016 11.9998L3.41016 13.4098L9.00016 18.9998L21.0002 6.99984L19.5902 5.58984L9.00016 16.1698Z" />
    </svg>
  )
}
