import { useEffect, useState } from 'react'

const screens: {
  [key: string]: string
  xs: string
  sm: string
  md: string
  lg: string
  xl: string
} = {
  xs: '375px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px'
}
type BreakpointState = { [K in keyof typeof screens]: boolean }

const useBreakpoint = (delay = 250): BreakpointState => {
  const [breakpoints, setBreakpoints] = useState<BreakpointState>()
  const sizes: [string, number][] = []

  // Define initial state
  const initialBreakpoints = <BreakpointState>{}

  // Populate sizes and breakpoints with
  Object.keys(screens).forEach(key => {
    sizes.push([key, parseInt(screens[key], 10)])
    initialBreakpoints[key] = false
  })

  let timeout: NodeJS.Timeout
  const setActiveBreakpoint = () => {
    const currentWidth = window.innerWidth
    const tmpBreakpoints = { ...breakpoints } as BreakpointState
    sizes.forEach(([key, width]) => {
      tmpBreakpoints[key] = currentWidth >= width
    })
    setBreakpoints(tmpBreakpoints)
  }

  // Debounce changes
  const handleResize = () => {
    clearTimeout(timeout)
    timeout = setTimeout(setActiveBreakpoint, delay)
  }

  // Initialize breakpoint
  useEffect(() => {
    setActiveBreakpoint()
  }, [])

  // Add resize listener
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => removeEventListener('resize', handleResize)
  }, [])

  return { ...breakpoints } as BreakpointState
}

export default useBreakpoint
