import { SVGProps } from 'react'
import c from 'clsx'

export default function Mode({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      data-icon="mode"
      xmlns="http://www.w3.org/2000/svg"
      className={c(['fill-current'], [className && className])}
      {...props}
    >
      <path
        opacity="0.3"
        d="M14.06 9.02002L5 18.08V19H5.92L14.98 9.94002L14.06 9.02002Z"
      />
      <path d="M18.37 3.29C18.17 3.09 17.92 3 17.66 3C17.4 3 17.15 3.1 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29ZM17.81 9.94L14.06 6.19L3 17.25V21H6.75L17.81 9.94ZM5 19V18.08L14.06 9.02L14.98 9.94L5.92 19H5Z" />
    </svg>
  )
}
