import c from 'clsx'
import { useState, useCallback, useEffect } from 'react'
import { Action } from '../../actions'
import { HeaderTemplate, IHeader } from '../../interfaces'
import InputContent from './_shared/InputContent'

function getTemplateClasses(
  currentTemplate: HeaderTemplate,
  selectedTemplate: HeaderTemplate
) {
  return c(
    'border px-2 py-1 rounded-sm cursor-pointer',
    currentTemplate === selectedTemplate
      ? 'bg-PrimaryDark text-White dark:bg-dark-PrimaryDark border-PrimaryDark dark:border-dark-PrimaryDark'
      : 'text-PrimaryDark hover:bg-PrimaryLighter dark:text-dark-PrimaryDark dark:hover:bg-dark-PrimaryLighter'
  )
}

// COMPONENT
export default function Header({
  header,
  dispatch
}: {
  header: IHeader
  dispatch: React.Dispatch<Action>
}) {
  const [headerData, setHeaderData] = useState(header)

  useEffect(() => {
    setHeaderData(header)
  }, [header])

  const handleInputChange = (field: keyof IHeader, value: string) => {
    setHeaderData(prevState => ({
      ...prevState,
      [field]: value
    }))
  }

  const handleBlur = useCallback(() => {
    dispatch({
      type: 'SET_HEADER',
      payload: headerData
    })
  }, [headerData, dispatch])

  const selectTemplate = (template: HeaderTemplate) => {
    dispatch({
      type: 'SET_HEADER',
      payload: { template }
    })
  }

  const content = [
    {
      label: 'Full name',
      value: headerData.fullName,
      name: 'fullName',
      placeholder: 'Ethan Parker'
    },
    {
      label: 'Email',
      value: headerData.email,
      name: 'email',
      placeholder: 'test@gmail.com'
    },
    {
      label: 'Phone',
      value: headerData.phone,
      name: 'phone',
      placeholder: '+372 77777777'
    },
    {
      label: 'Address',
      value: headerData.address,
      name: 'address',
      placeholder: 'Tallinn, Estonia'
    },
    {
      label: 'Website',
      value: headerData.website,
      name: 'website',
      placeholder: 'https://example.com'
    }
  ]

  if (header.template === 'Dark') {
    content.push({
      label: 'Position',
      value: headerData.position,
      name: 'position',
      placeholder: 'Frontend developer'
    })
  }

  return (
    <>
      <div className="mb-6 text-sm font-semibold dark:text-White50">
        Template:
        <div className="flex gap-2 pt-1">
          <div
            onClick={() => selectTemplate('Light')}
            className={getTemplateClasses('Light', headerData.template)}
          >
            Light Header
          </div>
          <div
            onClick={() => selectTemplate('Dark')}
            className={getTemplateClasses('Dark', headerData.template)}
          >
            Dark Header
          </div>
        </div>
      </div>
      {content.map(({ label, value, name, placeholder }) => (
        <InputContent
          className="mb-4"
          key={name}
          label={label}
          placeholder={placeholder}
          value={value}
          onChange={({ target }) =>
            handleInputChange(name as keyof IHeader, target.value)
          }
          onBlur={handleBlur}
        />
      ))}
    </>
  )
}
