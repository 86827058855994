import c from 'clsx'
import { SVGProps } from 'react'

export default function Visibility({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="visibility"
      fill="white"
      className={c(['fill-current '], [className && className])}
      {...props}
    >
      <path
        opacity="0.3"
        d="M12.0002 6C8.21018 6 4.83018 8.13 3.18018 11.5C4.83018 14.87 8.21018 17 12.0002 17C15.7902 17 19.1702 14.87 20.8202 11.5C19.1702 8.13 15.7902 6 12.0002 6ZM12.0002 16C9.52018 16 7.50018 13.98 7.50018 11.5C7.50018 9.02 9.52018 7 12.0002 7C14.4802 7 16.5002 9.02 16.5002 11.5C16.5002 13.98 14.4802 16 12.0002 16Z"
      />
      <path d="M12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 17C8.21 17 4.83 14.87 3.18 11.5C4.83 8.13 8.21 6 12 6C15.79 6 19.17 8.13 20.82 11.5C19.17 14.87 15.79 17 12 17ZM12 7C9.52 7 7.5 9.02 7.5 11.5C7.5 13.98 9.52 16 12 16C14.48 16 16.5 13.98 16.5 11.5C16.5 9.02 14.48 7 12 7ZM12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 10.12 10.62 9 12 9C13.38 9 14.5 10.12 14.5 11.5C14.5 12.88 13.38 14 12 14Z" />
    </svg>
  )
}
