import { Text, View, StyleSheet } from '@react-pdf/renderer'

//COMPONENT
export default function Title({ fullName }: { fullName: string }) {
  const firstWordExtractor = /^(\S+)(?:\s+(.*))?$/
  const match = fullName.match(firstWordExtractor)

  const firstWord = match ? match[1] : ''
  const remainingWords = match ? match[2] : ''

  // Create styles
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      gap: 8
    },
    title: {
      fontSize: 30
    }
  })

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{firstWord}</Text>
      <Text style={{ ...styles.title, fontWeight: 700 }}>{remainingWords}</Text>
    </View>
  )
}
