import c from 'clsx'
import { SVGProps } from 'react'

export default function VisibilityOff({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="visibility-off"
      fill="white"
      className={c(['fill-current '], [className && className])}
      {...props}
    >
      <path
        opacity="0.3"
        d="M12.0002 14C12.0402 14 12.0802 13.99 12.1202 13.99L9.51018 11.38C9.51018 11.42 9.50018 11.46 9.50018 11.5C9.50018 12.88 10.6202 14 12.0002 14ZM13.0102 9.21L14.2902 10.49C14.0302 9.92 13.5802 9.46 13.0102 9.21ZM20.8202 11.5C19.1702 8.13 15.7902 6 12.0002 6C11.3202 6 10.6602 6.09 10.0102 6.22L10.9302 7.14C11.2802 7.05 11.6302 7 12.0002 7C14.4802 7 16.5002 9.02 16.5002 11.5C16.5002 11.87 16.4402 12.22 16.3602 12.57L18.4102 14.62C19.3902 13.76 20.2202 12.71 20.8202 11.5ZM12.0002 17C12.9502 17 13.8702 16.87 14.7502 16.61L13.7702 15.63C13.2302 15.87 12.6302 16 12.0002 16C9.52018 16 7.50018 13.98 7.50018 11.5C7.50018 10.87 7.63018 10.27 7.86018 9.73L6.11018 7.97C4.89018 8.88 3.88018 10.07 3.18018 11.49C4.83018 14.86 8.21018 17 12.0002 17Z"
      />
      <path d="M12 5.99995C15.79 5.99995 19.17 8.12995 20.82 11.5C20.23 12.72 19.4 13.77 18.41 14.62L19.82 16.03C21.21 14.8 22.31 13.26 23 11.5C21.27 7.10995 17 3.99995 12 3.99995C10.73 3.99995 9.51 4.19995 8.36 4.56995L10.01 6.21995C10.66 6.08995 11.32 5.99995 12 5.99995ZM14.28 10.49L16.35 12.56C16.43 12.22 16.49 11.86 16.49 11.49C16.5 9.00995 14.48 6.99995 12 6.99995C11.63 6.99995 11.28 7.05995 10.93 7.13995L13 9.20995C13.58 9.45995 14.03 9.91995 14.28 10.49ZM2.01 3.86995L4.69 6.54995C3.06 7.82995 1.77 9.52995 1 11.5C2.73 15.89 7 19 12 19C13.52 19 14.98 18.71 16.32 18.18L19.74 21.6L21.15 20.19L3.42 2.44995L2.01 3.86995ZM9.51 11.37L12.12 13.98C12.08 13.99 12.04 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 11.45 9.51 11.42 9.51 11.37ZM6.11 7.96995L7.86 9.71995C7.63 10.27 7.5 10.87 7.5 11.5C7.5 13.98 9.52 16 12 16C12.63 16 13.23 15.87 13.77 15.64L14.75 16.62C13.87 16.86 12.95 17 12 17C8.21 17 4.83 14.87 3.18 11.5C3.88 10.07 4.9 8.88995 6.11 7.96995Z" />
    </svg>
  )
}
