import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { IResponsibility } from '../../interfaces'

//COMPONENT
export default function JobResponsibility({
  responsibility
}: {
  responsibility: IResponsibility
}) {
  // Create styles
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      gap: 4
    },
    dot: {
      fontSize: 8,
      fontWeight: 500
    },
    text: {
      fontSize: 8,
      fontWeight: 300,
      color: '#414141'
    }
  })

  return (
    <View style={styles.container}>
      <Text style={styles.dot}>•</Text>
      <Text style={styles.text}>{responsibility.value}</Text>
    </View>
  )
}
