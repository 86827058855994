import Modal from '../../../../organisms/Modal'
import { SectionName } from './interfaces'
import { useEffect, useState } from 'react'
import { Action } from '../actions'
import { State } from '../interfaces'
import Content from './Content'
import Structure from './Structure'
import Toolbar from './Toolbar'

//COMPONENT
export default function ConstructorBlock({
  refreshPdf,
  state,
  dispatch
}: {
  refreshPdf(): void
  state: State
  dispatch: React.Dispatch<Action>
}) {
  const resumesActiveTab = localStorage.getItem('resumesActiveTab')
  const [activeTab, setActiveTab] = useState<SectionName>(
    resumesActiveTab ? JSON.parse(resumesActiveTab) : SectionName.HEADER
  )

  useEffect(() => {
    localStorage.setItem('resumesActiveTab', JSON.stringify(activeTab))
  }, [activeTab])

  return (
    <div className="h-full pb-1">
      <Modal.Wrapper className="mb-2">
        <Modal.Body className="max-h-modal-content lg:max-h-modal-content-xl relative transition-height duration-400 flex-row">
          <Toolbar {...{ state, dispatch, refreshPdf }} />
        </Modal.Body>
      </Modal.Wrapper>

      <div className="flex flex-col lg:flex-row gap-2">
        <Modal.Wrapper className="w-full h-3/6 lg:w-4/12 lg:h-full overflow-hidden">
          <Modal.Header leftBox="Structure" />
          <Modal.Body className="max-h-modal-content lg:max-h-structure-resume relative transition-height duration-400 flex-row">
            <Structure
              state={state}
              dispatch={dispatch}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </Modal.Body>
        </Modal.Wrapper>

        <Modal.Wrapper className="h-3/6 mb-4 lg:mb-0 lg:w-8/12 lg:h-full">
          <Modal.Header leftBox="Content" />
          <Modal.Body className="max-h-modal-content lg:max-h-structure-resume p-6 lg:h-full">
            <Content section={activeTab} state={state} dispatch={dispatch} />
          </Modal.Body>
        </Modal.Wrapper>
      </div>
    </div>
  )
}
