import { Action } from '../../actions'
import { State } from '../../interfaces'
import { SectionName } from '../interfaces'
import Education from './Education'
import Experience from './Experience'
import Header from './Header'
import Skills from './Skills'
import Summary from './Summary'

//COMPONENT
export default function Content({
  section,
  state,
  dispatch
}: {
  section: SectionName
  state: State
  dispatch: React.Dispatch<Action>
}) {
  const { header, summary, experience, skills, education } = state

  // Header
  if (section === SectionName.HEADER) {
    return <Header {...{ header, dispatch }} />
  }

  // Summary
  if (section === SectionName.SUMMARY) {
    return <Summary {...{ summary, dispatch }} />
  }

  // Experience
  if (section === SectionName.EXPERIENCE) {
    return <Experience {...{ experience, dispatch }} />
  }

  // Skills
  if (section === SectionName.SKILLS) {
    return <Skills {...{ skills, dispatch }} />
  }

  // Education
  if (section === SectionName.EDUCATION) {
    return <Education {...{ education, dispatch }} />
  }

  return <div>Such section does not exist</div>
}
