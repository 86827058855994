import c from 'clsx'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { IDownloadButton } from '../../interfaces'
import Icon from '../../../../../organisms/Icon'

//COMPONENT
export default function DownloadButton({
  document,
  documentName
}: IDownloadButton) {
  return (
    <PDFDownloadLink document={document} fileName={`${documentName} Resume`}>
      {/* Props here - { blob, url, loading, error } */}
      {({ loading }) => (
        <button
          disabled={loading}
          className={c(
            'flex items-center justify-between py-1 pl-2 pr-1 gap-1',
            'cursor-pointer border rounded-md',
            'hover:bg-PrimaryLighter dark:hover:bg-dark-PrimaryLighter',
            'text-PrimaryLight10 dark:text-dark-PrimaryLight'
          )}
        >
          Download
          <Icon size={18} icon="download" />
        </button>
      )}
    </PDFDownloadLink>
  )
}
