import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { IJob } from '../../interfaces'

//COMPONENT
export default function JobHeading({
  jobTitle,
  companyName,
  jobLocation,
  startDate,
  endDate
}: IJob) {
  // Create styles
  const styles = StyleSheet.create({
    wrapper: { marginTop: 7, marginBottom: 2, gap: 2 },
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    jobTitle: {
      fontSize: 10,
      fontWeight: 500,
      color: '#333333'
    },
    jobLocation: {
      fontSize: 9,
      fontWeight: 300,
      fontStyle: 'italic',
      color: '#414141'
    },
    companyName: {
      fontSize: 9,
      fontWeight: 400,
      color: '#5d5d5d'
    },
    date: { fontSize: 8, fontWeight: 100, fontStyle: 'italic' }
  })

  return (
    <View style={styles.wrapper}>
      <View style={styles.container}>
        <Text style={styles.jobTitle}>{jobTitle}</Text>
        <Text style={styles.jobLocation}>{jobLocation}</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.companyName}>{companyName}</Text>
        <Text style={styles.date}>
          {startDate && endDate ? `${startDate} - ${endDate}` : ''}
        </Text>
      </View>
    </View>
  )
}
