import { State } from './interfaces'

export const initialResumeData: State = {
  showToolbar: false,
  header: {
    fullName: 'Ethan Parker',
    email: 'test@gmail.com',
    phone: '+372 77777777',
    address: 'Tallinn, Harjumaa, Estonia',
    website: 'https://test.com',
    position: 'Frontend developer',
    template: 'Light'
  },
  summary: {
    show: true,
    heading: 'Summary',
    summary:
      'Experienced Backend Engineer with over 8 years in designing scalable, high-performance APIs and microservices. Proficient in cloud platforms (AWS, GCP) and backend frameworks, with a strong focus on optimizing performance, ensuring data security, and collaborating effectively across teams. Skilled in managing complex databases and integrating third-party services to build robust applications.'
  },
  experience: {
    show: true,
    heading: 'Experience',
    jobs: [
      {
        id: 1,
        jobTitle: 'Backend Engineer',
        companyName: 'Amazon Web Services',
        jobLocation: 'Seattle, Washington, USA',
        startDate: 'Jan 2018',
        endDate: 'Oct 2020',
        responsibilities: [
          {
            id: 1,
            value:
              'Designed and implemented RESTful APIs to support front-end applications'
          },
          {
            id: 2,
            value: 'Developed and maintained serverless applications on AWS'
          },
          {
            id: 3,
            value: 'Optimized backend performance for high-traffic applications'
          },
          {
            id: 4,
            value:
              'Integrated third-party APIs and services into existing architecture'
          },
          {
            id: 5,
            value: 'Managed database schemas and wrote complex SQL queries'
          },
          {
            id: 6,
            value:
              'Implemented data validation and error handling in backend systems'
          },
          {
            id: 7,
            value:
              'Collaborated with cross-functional teams on large-scale projects'
          },
          {
            id: 8,
            value:
              'Ensured security best practices for data handling and storage'
          }
        ]
      },
      {
        id: 2,
        jobTitle: 'Data Analyst',
        companyName: 'Spotify',
        jobLocation: 'Stockholm, Sweden',
        startDate: 'Mar 2015',
        endDate: 'Dec 2017',
        responsibilities: [
          {
            id: 1,
            value: 'Analyzed user behavior data to identify patterns and trends'
          },
          {
            id: 2,
            value: 'Developed dashboards and reports for data-driven decisions'
          },
          {
            id: 3,
            value:
              'Worked closely with product teams to improve user experience'
          }
        ]
      },
      {
        id: 3,
        jobTitle: 'DevOps Engineer',
        companyName: 'Cisco Systems',
        jobLocation: 'San Jose, California, USA',
        startDate: 'Jul 2012',
        endDate: 'Feb 2015',
        responsibilities: [
          {
            id: 1,
            value:
              'Automated CI/CD pipelines for consistent deployments across environments'
          },
          {
            id: 2,
            value:
              'Maintained and monitored production environments to ensure high availability'
          },
          {
            id: 3,
            value:
              'Configured infrastructure as code using tools like Terraform and Ansible'
          },
          {
            id: 4,
            value:
              'Implemented monitoring and alerting systems for proactive incident response'
          },
          {
            id: 5,
            value:
              'Collaborated with development teams to streamline deployment workflows'
          },
          {
            id: 6,
            value:
              'Managed containerized applications with Docker and Kubernetes'
          }
        ]
      },
      {
        id: 4,
        jobTitle: 'UI/UX Designer',
        companyName: 'Adobe',
        jobLocation: 'Berlin, Germany',
        startDate: 'May 2009',
        endDate: 'Jun 2012',
        responsibilities: [
          {
            id: 1,
            value: 'Designed user interfaces for web and mobile applications'
          },
          {
            id: 2,
            value: 'Conducted user research to validate design choices'
          },
          {
            id: 3,
            value: 'Created wireframes and prototypes for new product features'
          }
        ]
      }
    ]
  },
  skills: {
    show: true,
    heading: 'Skills',
    skills: [
      {
        id: 1,
        title: 'Backend',
        details: [
          { id: 1, value: 'Node.js' },
          { id: 2, value: 'Express.js' },
          { id: 3, value: 'Python' },
          { id: 4, value: 'Django' },
          { id: 5, value: 'Ruby on Rails' },
          { id: 6, value: 'SQL' },
          { id: 7, value: 'MongoDB' }
        ]
      },
      {
        id: 2,
        title: 'DevOps',
        details: [
          { id: 1, value: 'Docker' },
          { id: 2, value: 'Kubernetes' },
          { id: 3, value: 'AWS' },
          { id: 4, value: 'Jenkins' },
          { id: 5, value: 'Terraform' },
          { id: 6, value: 'Ansible' },
          { id: 7, value: 'Linux' }
        ]
      },
      {
        id: 3,
        title: 'Data Science',
        details: [
          { id: 1, value: 'Python' },
          { id: 2, value: 'Pandas' },
          { id: 3, value: 'NumPy' },
          { id: 4, value: 'Scikit-Learn' },
          { id: 5, value: 'TensorFlow' },
          { id: 6, value: 'Keras' },
          { id: 7, value: 'Data Visualization' }
        ]
      },
      {
        id: 4,
        title: 'Mobile Development',
        details: [
          { id: 1, value: 'Flutter' },
          { id: 2, value: 'React Native' },
          { id: 3, value: 'Swift' },
          { id: 4, value: 'Kotlin' },
          { id: 5, value: 'Java' },
          { id: 6, value: 'Xamarin' },
          { id: 7, value: 'Firebase' }
        ]
      },
      {
        id: 5,
        title: 'UI/UX Design',
        details: [
          { id: 1, value: 'Figma' },
          { id: 2, value: 'Adobe XD' },
          { id: 3, value: 'Sketch' },
          { id: 4, value: 'InVision' },
          { id: 5, value: 'Prototyping' },
          { id: 6, value: 'Wireframing' },
          { id: 7, value: 'User Research' }
        ]
      }
    ]
  },
  education: {
    show: true,
    heading: 'Education',
    schools: [
      {
        id: 1,
        schoolName: 'Harvard University',
        schoolLocation: 'Cambridge, Massachusetts, United States',
        degree: 'Bachelor degree',
        major: 'Programs',
        startDate: 'Sep 2014',
        endDate: 'Jun 2019'
      }
    ]
  }
}
