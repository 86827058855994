import { IProject } from '../../../../lib/models'
import { BadgeType } from './Label'

export const projects: IProject[] = [
  {
    id: 1,
    title: 'Mooncascade',
    subtitle: 'Many interesting projects with different technologies',
    description:
      'In our team, I played a key role in building a large-scale white-label app serving over 250 agencies across five continents — from developing new features to fixing bugs and improving code quality. I also worked on marketing web applications with third-party integrations, making them fast and user-friendly.\n\nMy responsibilities included writing and reviewing code, developing unit and E2E tests, and preparing releases. I also handled task estimation, development planning, and code reviews. Beyond the technical side, I conducted interviews, led workshops, and helped my teammates level up their skills.\n\nTogether with the team, we attended conferences and exchanged great ideas, which we then brought into our projects.',
    badgeType: [BadgeType.ts, BadgeType.vue, BadgeType.angular, BadgeType.next],
    mainImage: './photosOfProjects/Mooncascade/Mooncascade.webp',
    images: [
      './photosOfProjects/Mooncascade/Mooncascade_1.webp',
      './photosOfProjects/Mooncascade/Mooncascade_2.webp',
      './photosOfProjects/Mooncascade/Mooncascade_3.webp',
      './photosOfProjects/Mooncascade/Mooncascade_4.webp',
      './photosOfProjects/Mooncascade/Mooncascade_5.webp',
      './photosOfProjects/Mooncascade/Mooncascade_6.webp',
      './photosOfProjects/Mooncascade/Mooncascade_7.webp',
      './photosOfProjects/Mooncascade/Mooncascade_8.webp'
    ],
    icon: 'mooncascade_icon',
    technology: [
      'Angular',
      'Ionic',
      'TypeScript',
      'Jest',
      'Capacitor',
      'Android',
      'iOS',
      'Git',
      'GitLab'
    ],
    bgColor: '#161623',
    link: 'https://www.mooncascade.com/'
  },
  {
    id: 2,
    title: 'Eureca',
    subtitle: 'Complex internal web application',
    description:
      'At this company, I focused on maintaining, refactoring, and enhancing existing features while working with extensive databases and developing new functionalities. I also mentored and trained junior developers and interns, helping them grow into their roles. Alongside issue handling and bug fixes, I contributed by writing documentation to keep everything clear and organized.',
    badgeType: [BadgeType.ts, BadgeType.react],
    mainImage: './photosOfProjects/Eureca/eureca.webp',
    images: ['./photosOfProjects/Eureca/eureca_1.webp'],
    icon: 'evrika_icon',
    technology: [
      'React',
      'JavaScript',
      'SCSS',
      'REST API',
      'Atomic design',
      'Axios',
      'Git',
      'GitHub'
    ],
    bgColor: '#E6E6E6',
    link: 'https://hr.eureca.ru/'
  },
  {
    id: 3,
    title: 'Freelance',
    subtitle: 'Website based on React and SCSS',
    description:
      'In this role, I collaborated directly with clients to create clear technical requirements and managed tight deadlines to keep projects on track. I designed and developed an admin panel app from scratch to support product management and supply chain oversight, and built an auction website for a client in Ireland. My responsibilities also included enhancing existing websites, fixing bugs, adding new features, and designing and implementing project architecture to ensure scalable and efficient solutions.',
    badgeType: [BadgeType.js, BadgeType.react],
    mainImage: './photosOfProjects/Freelance/Freelance.webp',
    images: [
      './photosOfProjects/Freelance/Freelance_1.webp',
      './photosOfProjects/Freelance/Freelance_2.webp',
      './photosOfProjects/Freelance/Freelance_3.webp',
      './photosOfProjects/Freelance/Freelance_4.webp',
      './photosOfProjects/Freelance/Freelance_5.webp',
      './photosOfProjects/Freelance/Freelance_6.webp',
      './photosOfProjects/Freelance/Freelance_7.webp',
      './photosOfProjects/Freelance/Freelance_8.webp'
    ],
    icon: 'jun_icon',
    technology: [
      'JavaScript',
      'React',
      'Core UI',
      'SCSS',
      'REST API',
      'Chart.js',
      'Animate.css',
      'Axios',
      'Pug.js',
      'SASS',
      'Git'
    ]
  }
]
