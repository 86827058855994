export const GAME = {
  WIDTH: 1944,
  HEIGHT: 936,
  CELL_SIZE: 32,
  CELL_SPACE_BETWEEN: 4,
  BOARD_SPEED: 5,
  BOARD_COLOR: '#D0F0C0',
  BOARD_COLOR_DARK: '#E8F1FF1A',
  SNAKE_COLOR: '#d1881b',
  SNAKE_MOUTH_COLOR: 'white',
  SNAKE_VELOCITY: 4
}
