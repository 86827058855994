import { Text, StyleSheet, View } from '@react-pdf/renderer'
import { ISkill } from '../../interfaces'

//COMPONENT
export default function Details({ skills }: { skills: ISkill[] }) {
  // Create styles
  const styles = StyleSheet.create({
    container: {
      display: 'flex'
    },
    details: {
      fontSize: 9,
      fontFamily: 'Lora',
      fontWeight: 400,
      color: '#414141',
      height: 12,
      marginLeft: '6px'
    }
  })

  return (
    <View style={styles.container}>
      {skills.map(({ details }, index) => (
        <Text key={index} style={styles.details}>
          {details.map(({ value }) => value).join(',  ')}
        </Text>
      ))}
    </View>
  )
}
