import { useState, useCallback, useEffect, useRef } from 'react'
import { getNewId } from '../../../../../../lib/functions/common'
import { Action } from '../../actions'
import InputContent from './_shared/InputContent'
import AddRemoveItem from './_shared/AddRemoveItem'
import { IEducation } from '../../interfaces'

// COMPONENT
export default function Education({
  education,
  dispatch
}: {
  education: IEducation
  dispatch: React.Dispatch<Action>
}) {
  const [headingData, setHeadingData] = useState(education.heading)
  const [schoolsData, setSchoolsData] = useState(education.schools)

  const newSchoolContainer = useRef<HTMLDivElement>(null)

  const handleAddSchoolFocus = () => {
    setTimeout(() => {
      if (newSchoolContainer.current) {
        newSchoolContainer.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    })
  }

  useEffect(() => {
    setHeadingData(education.heading)
    setSchoolsData(education.schools)
  }, [education])

  const updateEducationState = useCallback(() => {
    dispatch({
      type: 'SET_EDUCATION',
      payload: { heading: headingData, schools: schoolsData }
    })
  }, [headingData, schoolsData, dispatch])

  const handleInputChange = (
    schoolId: number,
    fieldName: string,
    value: string
  ) => {
    setSchoolsData(prevState =>
      prevState.map(item =>
        item.id === schoolId ? { ...item, [fieldName]: value } : item
      )
    )
  }

  const handleAddSchool = () => {
    setSchoolsData(prevState => [
      ...prevState,
      {
        id: getNewId(prevState),
        schoolName: '',
        degree: '',
        major: '',
        schoolLocation: '',
        startDate: '',
        endDate: ''
      }
    ])

    handleAddSchoolFocus()
  }

  const handleRemoveSchool = () => {
    setSchoolsData(prevState => {
      const updatedSchools = [...prevState]
      updatedSchools.pop()

      setTimeout(
        () =>
          dispatch({
            type: 'SET_EDUCATION',
            payload: { schools: updatedSchools }
          }),
        300
      )

      return updatedSchools
    })
  }

  return (
    <div>
      <InputContent
        className="mb-8"
        label="Section Heading"
        placeholder="Education"
        value={headingData}
        onChange={({ target }) => setHeadingData(target.value)}
        onBlur={updateEducationState}
      />
      {schoolsData.map(
        ({
          id,
          schoolName,
          degree,
          schoolLocation,
          major,
          startDate,
          endDate
        }) => (
          <div key={id} className="mb-6">
            <div className="w-full h-2.5 bg-PrimaryLight10 dark:bg-Grey mb-2"></div>
            {[
              {
                label: 'School Name',
                value: schoolName,
                name: 'schoolName',
                placeholder: 'Tallinn University of Technology'
              },
              {
                label: 'Degree',
                value: degree,
                name: 'degree',
                placeholder: 'Bachelor degree'
              },
              {
                label: 'School Location',
                value: schoolLocation,
                name: 'schoolLocation',
                placeholder: 'Tallinn, Estonia'
              },
              {
                label: 'Major',
                value: major,
                name: 'major',
                placeholder: 'Computer Science'
              },
              {
                label: 'Start Date',
                value: startDate,
                name: 'startDate',
                placeholder: 'Aug 2021'
              },
              {
                label: 'End Date',
                value: endDate,
                name: 'endDate',
                placeholder: 'Aug 2022 / Present'
              }
            ].map(({ label, value, name, placeholder }) => (
              <InputContent
                className="mb-4"
                key={name}
                label={label}
                placeholder={placeholder}
                value={value}
                onChange={({ target }) =>
                  handleInputChange(id, name, target.value)
                }
                onBlur={updateEducationState}
              />
            ))}
          </div>
        )
      )}

      <div ref={newSchoolContainer} tabIndex={0} aria-hidden="true">
        <AddRemoveItem
          handleAddItem={handleAddSchool}
          handleRemoveItem={handleRemoveSchool}
          itemName="School"
          disableRemove={!schoolsData.length}
        />
      </div>
    </div>
  )
}
