import c from 'clsx'
import { SVGProps } from 'react'

export default function Download({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="download"
      fill="white"
      className={c(['fill-current'], [className && className])}
      {...props}
    >
      <path
        opacity="0.3"
        d="M13.0001 9V5H11.0001V11H9.83008L12.0001 13.17L14.1701 11H13.0001V9Z"
      />
      <path d="M15 9V3H9V9H5L12 16L19 9H15ZM12 13.17L9.83 11H11V5H13V11H14.17L12 13.17ZM5 18H19V20H5V18Z" />
    </svg>
  )
}
