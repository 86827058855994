import c from 'clsx'
import { SVGProps } from 'react'

export default function Rewrite({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="rewrite"
      fill="white"
      className={c(['fill-current '], [className && className])}
      {...props}
    >
      <path d="M19.9716 6.3404C19.947 6.2676 19.9076 6.2004 19.853 6.146L16.854 3.147C16.762 3.0548 16.6302 3 16.5 3H11.5C11.2238 3 11 3.2238 11 3.5V9H6.5C6.2238 9 6 9.2238 6 9.5V20.5C6 20.7762 6.2238 21 6.5 21H14.5C14.7762 21 15 20.7762 15 20.5V15H19.5C19.7762 15 20 14.7762 20 14.5V6.5C20 6.4438 19.9896 6.3936 19.9716 6.3404C19.9896 6.3936 19.947 6.2676 19.9716 6.3404ZM17 4.7072L18.2928 6H17V4.7072ZM12 10.7072L13.2928 12H12V10.7072ZM14 20H7V10H11V12.5C11 12.7762 11.2238 13 11.5 13H14V20ZM19 14H15V12.5C15 12.3698 14.9452 12.238 14.853 12.146L12 9.293V4H16V6.5C16 6.7762 16.2238 7 16.5 7H19V14Z" />
      <path d="M5.5 4.99995C4.1214 4.99995 3 6.12135 3 7.49995C3 8.16755 3.26 8.79555 3.7322 9.26775C3.8298 9.36535 3.9578 9.41415 4.0858 9.41415C4.2138 9.41415 4.3416 9.36535 4.4394 9.26775C4.6348 9.07255 4.6348 8.75595 4.4394 8.56055C4.156 8.27735 4 7.90055 4 7.49995C4 6.67275 4.6728 5.99995 5.5 5.99995H6.5H7.2928L6.5 6.79295L6.1464 7.14655C5.951 7.34175 5.951 7.65835 6.1464 7.85355C6.244 7.95115 6.372 7.99995 6.5 7.99995C6.628 7.99995 6.756 7.95115 6.8536 7.85355L8.8536 5.85355C9.049 5.65835 9.049 5.34175 8.8536 5.14655L6.8536 3.14655C6.6584 2.95115 6.3416 2.95115 6.1466 3.14655C5.9512 3.34175 5.9512 3.65835 6.1466 3.85355L6.5002 4.20715L7.2928 4.99995H6.5H5.5Z" />
      <path d="M8 14.5C8 14.7762 8.2238 15 8.5 15H11.5H12.5C12.7762 15 13 14.7762 13 14.5C13 14.2238 12.7762 14 12.5 14H12H8.5C8.2238 14 8 14.2238 8 14.5Z" />
      <path d="M12.5 16H8.5C8.2238 16 8 16.2238 8 16.5C8 16.7762 8.2238 17 8.5 17H12.5C12.7762 17 13 16.7762 13 16.5C13 16.2238 12.7762 16 12.5 16Z" />
      <path d="M12.5 18H8.5C8.2238 18 8 18.2238 8 18.5C8 18.7762 8.2238 19 8.5 19H12.5C12.7762 19 13 18.7762 13 18.5C13 18.2238 12.7762 18 12.5 18Z" />
    </svg>
  )
}
