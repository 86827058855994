import { useState, useCallback, useEffect, useRef } from 'react'
import { Reorder } from 'framer-motion'
import { IDetail, ISkills } from '../../../interfaces'
import { getNewId } from '../../../../../../../lib/functions/common'
import { Action } from '../../../actions'
import InputContent from '../_shared/InputContent'
import SkillItem from './SkillItem'
import NewItemButton from '../_shared/NewItemButton'

export default function Skills({
  skills,
  dispatch
}: {
  skills: ISkills
  dispatch: React.Dispatch<Action>
}) {
  const [headingData, setHeadingData] = useState(skills.heading)
  const [skillsData, setSkillsData] = useState(skills.skills)
  const [animation, setAnimation] = useState(true)
  const [openSkillId, setOpenSkillId] = useState(0)

  const [processing, setProcessing] = useState(false)
  const newSkillContainer = useRef<HTMLDivElement>(null)

  const handleAddSkillFocus = () => {
    setTimeout(() => {
      if (newSkillContainer.current) {
        newSkillContainer.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    })
  }

  useEffect(() => {
    setHeadingData(skills.heading)
    setSkillsData(skills.skills)
  }, [skills])

  const updateSkillsState = useCallback(() => {
    dispatch({
      type: 'SET_SKILLS',
      payload: { skills: skillsData, heading: headingData }
    })
  }, [headingData, skillsData, dispatch])

  const handleSkillNameChange = (id: number, value: string) => {
    setSkillsData(prevState =>
      prevState.map(item => (item.id === id ? { ...item, title: value } : item))
    )
  }

  const handleRemoveSkill = (id: number) => {
    setAnimation(false)
    setProcessing(true)

    setSkillsData(prevState => {
      const updatedSkills = prevState.filter(item => item.id !== id)
      setTimeout(() => {
        dispatch({
          type: 'SET_SKILLS',
          payload: { skills: updatedSkills }
        })
        setAnimation(true)
        setProcessing(false)
      }, 300)
      return updatedSkills
    })
  }

  const handleAddSkill = (value: string) => {
    setProcessing(true)

    setSkillsData(prevState => {
      const newSkills = [
        ...prevState,
        { id: getNewId(prevState), title: value, details: [] }
      ]

      dispatch({
        type: 'SET_SKILLS',
        payload: { skills: newSkills }
      })

      return newSkills
    })

    setTimeout(() => setProcessing(false))
    handleAddSkillFocus()
  }

  const handleOpenSkill = (id: number) => {
    setAnimation(false)
    setOpenSkillId(openSkillId === id ? 0 : id)
    setTimeout(() => setAnimation(true), 300)
  }

  const handleChangeDetail = (
    skillId: number,
    detailId: number,
    value: string
  ) => {
    setSkillsData(prevState =>
      prevState.map(item =>
        item.id === skillId
          ? {
              ...item,
              details: item.details.map(detail =>
                detail.id === detailId ? { ...detail, value } : detail
              )
            }
          : item
      )
    )
  }

  const handleDetailReorder = (skillsId: number, details: IDetail[]) => {
    setSkillsData(prevState =>
      prevState.map(item =>
        item.id === skillsId ? { ...item, details } : item
      )
    )
  }

  const handleRemoveDetail = (skillsId: number, detailId: number) => {
    setAnimation(false)

    setSkillsData(prevState => {
      const updatedSkills = prevState.map(item =>
        item.id === skillsId
          ? {
              ...item,
              details: item.details.filter(detail => detail.id !== detailId)
            }
          : item
      )

      setTimeout(
        () =>
          dispatch({
            type: 'SET_SKILLS',
            payload: { skills: updatedSkills }
          }),
        300
      )

      return updatedSkills
    })

    setTimeout(() => setAnimation(true), 300)
  }

  const handleAddDetail = (skillsId: number, value: string) => {
    setProcessing(true)

    setSkillsData(prevState => {
      const newSkills = prevState.map(skill =>
        skill.id === skillsId
          ? {
              ...skill,
              details: [
                ...skill.details,
                { id: getNewId(skill.details), value }
              ]
            }
          : skill
      )

      setTimeout(() => {
        setProcessing(false)
        dispatch({
          type: 'SET_SKILLS',
          payload: { skills: newSkills }
        })
      }, 300)

      return newSkills
    })
  }

  return (
    <>
      <InputContent
        className="mb-4"
        label="Section Heading"
        placeholder="Skills"
        value={headingData}
        onChange={({ target }) => setHeadingData(target.value)}
        onBlur={updateSkillsState}
      />

      <div className="mb-4">
        <div className="text-sm font-semibold dark:text-White50 mb-1">
          Skills
        </div>

        <Reorder.Group axis="y" onReorder={setSkillsData} values={skillsData}>
          {skillsData.map(skill => (
            <SkillItem
              key={skill.id}
              item={skill}
              animation={animation}
              onSkillNameChange={({ target }) =>
                handleSkillNameChange(skill.id, target.value)
              }
              onRemoveItem={() => {
                if (!processing) {
                  handleRemoveSkill(skill.id)
                }
              }}
              updateSkillsState={updateSkillsState}
              openSkillId={openSkillId}
              handleOpenSkill={handleOpenSkill}
              handleChangeDetail={handleChangeDetail}
              handleRemoveDetail={(skillId, detailId) => {
                if (!processing) {
                  handleRemoveDetail(skillId, detailId)
                }
              }}
              handleAddDetail={(skillsId, value) => {
                if (!processing) {
                  handleAddDetail(skillsId, value)
                }
              }}
              handleDetailReorder={handleDetailReorder}
            />
          ))}

          <div
            className="border border-Grey rounded-md pb-2 px-2"
            ref={newSkillContainer}
            tabIndex={0}
            aria-hidden="true"
          >
            <NewItemButton
              onNewItemClick={value => {
                if (!processing) {
                  handleAddSkill(value)
                }
              }}
              placeholder="Add new skill"
            />
          </div>
        </Reorder.Group>
      </div>
    </>
  )
}
