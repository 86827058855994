import c from 'clsx'
import { Reorder, useDragControls, useMotionValue } from 'framer-motion'
import { IResponsibility } from '../../../interfaces'
import {
  disableScroll,
  enableScroll
} from '../../../../../../../lib/functions/common'
import Icon from '../../../../../../organisms/Icon'

export interface ExperienceItemProps {
  item: IResponsibility
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onComplete: () => void
  onRemove: () => void
  animation: boolean
}

export default function ReorderItem({
  item,
  onChange,
  onComplete,
  onRemove,
  animation
}: ExperienceItemProps) {
  const y = useMotionValue(0)
  const dragControls = useDragControls()

  return (
    <Reorder.Item
      id={item.id.toString()}
      value={item}
      style={{ y }}
      dragListener={false}
      dragControls={dragControls}
      transition={animation ? {} : { duration: 0 }}
      onDragEnd={() => setTimeout(onComplete, 300)}
    >
      <div className="flex items-center custom-no-select">
        <div
          className="reorder-handle cursor-grab bg-gray-300 p-1 text-l"
          onPointerDown={event => dragControls.start(event)}
          onTouchMove={disableScroll}
          onTouchEnd={enableScroll}
        >
          ⠿
        </div>

        <input
          value={item.value}
          placeholder="Writing documentation"
          className={c(
            'w-full bg-transparent outline-none mb-px',
            'border-b ml-1 focus:border-b-2 focus:mb-0',
            'text-Grey dark:text-LightBloom100op75',
            'focus:text-Primary focus:dark:text-dark-Primary',
            'placeholder:text-LightBloom100 placeholder:dark:text-LightBloom100op50'
          )}
          onChange={onChange}
          onBlur={onComplete}
        />

        <button
          className={c(
            'ml-1 mt-0.5 text-nowrap cursor-pointer border rounded-md',
            'text-ErrorLight text-opacity-60 dark:text-Error',
            'bg-White dark:bg-dark-White',
            'hover:bg-ErrorLight hover:bg-opacity-25 dark:hover:bg-Error',
            'disabled:opacity-40 disabled:cursor-default disabled:bg-transparent'
          )}
          onClick={onRemove}
        >
          <Icon size={16} icon="remove" />
        </button>
      </div>
    </Reorder.Item>
  )
}
