import Modal from '../../../../organisms/Modal'
import experience from '../data/experience.json'

const Experience = (): JSX.Element => (
  <Modal.Wrapper>
    <Modal.Body className="xl:max-h-full p-4 lg:p-8">
      {experience.experience.data.jobs.map(
        ({
          id,
          jobTitle,
          companyName,
          jobLocation,
          startDate,
          endDate,
          responsibilities
        }) => (
          <div key={id} className="mb-4 lg:mb-5">
            <h3 className="text-xl font-semibold dark:text-dark-Primary mb-2 lg:mb-6">
              {jobTitle}
            </h3>
            <div className="flex flex-col lg:flex-row">
              <div className="lg:w-4/12">
                <div className="text-base font-semibold dark:text-White80 mb-1">
                  {companyName}
                </div>
                <div className="text-base dark:text-Grey10 font-normal mb-1 lg:mb-4">
                  {jobLocation}
                </div>
                <div className="text-sm dark:text-Grey font-normal mb-1">
                  {startDate} - {endDate}
                </div>
              </div>
              <div className="lg:w-8/12">
                {responsibilities && (
                  <>
                    <div className="text-base font-medium dark:text-White80 mt-3 mb-2 lg:mt-0 lg:mb-2">
                      Responsibilities
                    </div>
                    {responsibilities.map(({ id, value }) => (
                      <div key={id} className="flex mb-2 lg:mb-1 ml-3 lg:ml-0">
                        <div className="mr-1">•</div>
                        <div
                          key={id}
                          className="text-sm font-normal dark:text-Grey "
                        >
                          {value}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        )
      )}
    </Modal.Body>
  </Modal.Wrapper>
)

export default Experience
