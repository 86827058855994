import c from 'clsx'
import { useState } from 'react'
import Icon from '../../../../../../../organisms/Icon'

//COMPONENT
export default function ResumeButton({
  name,
  handleRename,
  handleSelect,
  handleExport,
  handleOverwrite,
  handleDelete
}: {
  name: string
  handleRename(newName: string): void
  handleSelect(): void
  handleExport(): void
  handleOverwrite(): void
  handleDelete(): void
}) {
  const [renameMode, setRenameMode] = useState(false)
  const [newName, setNewName] = useState('')

  const buttonStyles = c(
    'flex items-center px-1 cursor-pointer',
    'cursor-pointer rounded-md',
    'text-PrimaryLight10 dark:text-dark-PrimaryLight',
    'hover:bg-PrimaryLighter dark:hover:bg-dark-PrimaryLighter'
  )

  return (
    <div
      className={c(
        'shadow-normal dark:shadow-dark-normal',
        'flex items-center mb-2 gap-2 pr-2',
        'rounded-md border border-Grey20 dark:border-Grey'
      )}
    >
      {renameMode ? (
        <div className="flex w-full pl-2 py-1 h-9">
          <input
            value={newName}
            onChange={({ target }) => setNewName(target.value)}
            className={c(
              'bg-PrimaryLighter mr-4',
              'outline-none w-full',
              'text-Grey dark:text-LightBloom100op75',
              'focus:text-Primary focus:dark:text-dark-Primary',
              'placeholder:text-LightBloom100 placeholder:dark:text-LightBloom100op50'
            )}
            onKeyDown={event => {
              if (event.key === 'Enter' && !!newName) {
                setRenameMode(false)

                if (name !== newName) {
                  handleRename(newName)
                }
              }
            }}
          />
          <button
            onClick={() => {
              setRenameMode(false)
            }}
            className={c(buttonStyles, 'hover:bg-Grey30')}
          >
            <Icon icon="close" className="ml-auto mr-auto text-displayBlack" />
          </button>
          <button
            onClick={() => {
              setRenameMode(false)

              if (name !== newName && !!newName) {
                handleRename(newName)
              }
            }}
            className={c(buttonStyles, 'hover:bg-SuccessLighter')}
          >
            <Icon icon="check" className="ml-auto mr-auto text-SuccessDark" />
          </button>
        </div>
      ) : (
        <>
          <div
            title={name}
            className={c(
              'flex items-center w-full px-2 py-1 gap-2 h-9',
              'rounded-md',
              'bg-White dark:bg-dark-White'
            )}
          >
            {name}
          </div>

          <button
            onClick={() => {
              setNewName(name)
              setRenameMode(true)
            }}
            className={buttonStyles}
          >
            Rename
          </button>
        </>
      )}

      <button onClick={handleSelect} className={buttonStyles}>
        Select
      </button>

      <button onClick={handleExport} className={buttonStyles}>
        Export
      </button>

      <button onClick={handleOverwrite} className={buttonStyles}>
        Overwrite
      </button>

      <button
        onClick={handleDelete}
        className={c(
          'flex items-center px-1 cursor-pointer',
          'border-ErrorDark text-ErrorDark opacity-80',
          'rounded-md',
          'hover:bg-ErrorLighter hover:dark:bg-ErrorDark hover:dark:bg-opacity-30'
        )}
      >
        Delete
      </button>
    </div>
  )
}
