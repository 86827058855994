import { Text, View, StyleSheet, Svg, Path, Link } from '@react-pdf/renderer'

//COMPONENT
export default function RightContainer({
  email,
  phone,
  address,
  website
}: {
  email?: string
  phone?: string
  address?: string
  website?: string
}) {
  // Create styles
  const styles = StyleSheet.create({
    container: {
      width: '50%',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      marginTop: 2,
      paddingTop: 24,
      paddingBottom: 18
    },
    text: {
      fontSize: 12,
      marginLeft: 5,
      fontWeight: 400
    },
    link: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      color: '#ffffff'
    }
  })

  return (
    <View style={styles.container}>
      <Link src={`mailto:${email}`} style={styles.link}>
        <Svg fill="#ffffff" width={12} height={8} viewBox="0 0 20 16">
          <Path d="M18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 2L10 6.99L2 2H18ZM18 14H2V4L10 9L18 4V14Z" />
        </Svg>
        <Text style={styles.text}>{email?.trim() || 'test@test.com'}</Text>
      </Link>
      {phone && (
        <Link src={`tel:${phone}`} style={styles.link}>
          <Svg fill="#ffffff" width={6} height={14} viewBox="0 0 14 22">
            <Path d="M11.9998 0.00999999L1.99977 0C0.899765 0 0.00976562 0.9 0.00976562 2V20C0.00976562 21.1 0.899765 22 1.99977 22H11.9998C13.0998 22 13.9998 21.1 13.9998 20V2C13.9998 0.9 13.0998 0.00999999 11.9998 0.00999999ZM11.9998 18H1.99977V4H11.9998V18Z" />
          </Svg>
          <Text style={styles.text}>{phone}</Text>
        </Link>
      )}
      {address && (
        <Link
          src={`https://www.google.com/maps/search/?api=1&query=${address}`}
          style={styles.link}
        >
          <Svg fill="#ffffff" width={8} height={12} viewBox="0 0 16 20">
            <Path d="M8 0C12.2 0 16 3.22 16 8.2C16 11.52 13.33 15.45 8 20C2.67 15.45 0 11.52 0 8.2C0 3.22 3.8 0 8 0ZM14 8.2C14 4.57 11.35 2 8 2C4.65 2 2 4.57 2 8.2C2 10.54 3.95 13.64 8 17.34C12.05 13.64 14 10.54 14 8.2ZM8 10C6.9 10 6 9.1 6 8C6 6.9 6.9 6 8 6C9.1 6 10 6.9 10 8C10 9.1 9.1 10 8 10Z" />
          </Svg>
          <Text style={styles.text}>{address}</Text>
        </Link>
      )}
      {website && (
        <Link src={website} style={styles.link}>
          <Svg fill="#ffffff" width={11} height={11} viewBox="0 0 20 20">
            <Path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8.99 17.93C5.06 17.43 2 14.07 2 10C2 9.39 2.08 8.79 2.21 8.22L6.99 13V14C6.99 15.1 7.89 16 8.99 16V17.93ZM15.89 15.4C15.63 14.59 14.89 14 13.99 14H12.99V11C12.99 10.45 12.54 10 11.99 10H5.99V8H7.99C8.54 8 8.99 7.55 8.99 7V5H10.99C12.09 5 12.99 4.1 12.99 3V2.59C15.92 3.77 18 6.65 18 10C18 12.08 17.19 13.98 15.89 15.4Z" />
          </Svg>
          <Text style={styles.text}>{website}</Text>
        </Link>
      )}
    </View>
  )
}
