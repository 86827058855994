import c from 'clsx'
import { useEffect, useRef, useState } from 'react'
import Icon from '../../../organisms/Icon'
import BoardService from './boardService'
import { GAME } from './constants'
import SnakeGame from './game'
import { EDirections, IGame } from './interfaces'
import SnakeService from './snakeService'

const ProgressBar = ({
  label,
  progress
}: {
  label: string
  progress: number
}) => (
  <div className="w-full bg-Grey30 rounded-full h-6 relative">
    <div className="absolute inset-0 flex items-center justify-center font-semibold text-white text-sm z-10 dark:text-Black">
      {label} = {progress}%
    </div>
    <div
      className="bg-SuccessDark h-full rounded-full transition-all"
      style={{ width: `${progress}%` }}
    ></div>
  </div>
)

const SnakeVer2 = (): JSX.Element => {
  const [boardProgress, setBoardProgress] = useState(0)
  const [snakeProgress, setSnakeProgress] = useState(0)
  const [gameOver, setGameOver] = useState(false)
  const game = useRef<IGame>()

  useEffect(() => {
    const canvas: HTMLCanvasElement = document.getElementById(
      'my-canvas'
    ) as HTMLCanvasElement
    const canvasContext = canvas?.getContext('2d') as CanvasRenderingContext2D

    game.current = new SnakeGame(
      canvasContext,
      setBoardProgress,
      setSnakeProgress,
      setGameOver,
      new SnakeService(),
      new BoardService()
    )

    game.current.start()

    return () => {
      game?.current?.stop()
    }
  }, [])

  const leftHandler = () => {
    game?.current?.keyDownEvents({ code: EDirections.Left } as KeyboardEvent)
  }

  const rightHandler = () => {
    game?.current?.keyDownEvents({ code: EDirections.Right } as KeyboardEvent)
  }

  const upHandler = () => {
    game?.current?.keyDownEvents({ code: EDirections.Up } as KeyboardEvent)
  }

  const downHandler = () => {
    game?.current?.keyDownEvents({ code: EDirections.Down } as KeyboardEvent)
  }

  const skipBoard = () => {
    game?.current?.handleSkipBoard()
  }

  const skipSnake = () => {
    game?.current?.handleSkipSnake()
  }

  return (
    <div className="w-full h-full flex items-center flex-col justify-start relative">
      <canvas
        id="my-canvas"
        width={GAME.WIDTH}
        height={GAME.HEIGHT}
        className="w-full"
      ></canvas>

      <div
        className={c(
          [
            boardProgress === 100 && snakeProgress === 100
              ? 'hidden'
              : 'visible'
          ],
          [
            'absolute flex flex-col top-4 right-4 w-96  bg-PrimaryLight30 p-2 rounded-md'
          ]
        )}
      >
        <ProgressBar label="Board" progress={boardProgress} />
        <button
          onClick={skipBoard}
          className="mt-1 mb-2 bg-DarkGrey p-1 rounded-md text-LightBloom50"
        >
          SKIP
        </button>

        {boardProgress === 100 && (
          <>
            <ProgressBar label="Snake" progress={snakeProgress} />
            <button
              onClick={skipSnake}
              className="mt-1 bg-DarkGrey p-1 rounded-md text-LightBloom50"
            >
              SKIP
            </button>
          </>
        )}
      </div>

      {gameOver && (
        <div className="absolute inset-0 flex items-center justify-center text-ErrorLight">
          <p className="text-center text-4xl font-bold">GAME OVER</p>
        </div>
      )}

      <div className="flex mt-12 lg:hidden flex-col">
        <button onTouchStart={upHandler} className="flex justify-center">
          <Icon
            size={62}
            icon="arrowUp"
            className="dark:text-dark-PrimaryLight text-LightBloom150"
          />
        </button>

        <div className="flex justify-center">
          <button onTouchStart={leftHandler} className="mr-14">
            <Icon
              size={62}
              icon="arrowBack"
              className="dark:text-dark-PrimaryLight text-LightBloom150"
            />
          </button>

          <button onTouchStart={rightHandler}>
            <Icon
              size={62}
              icon="arrowForward"
              className="dark:text-dark-PrimaryLight text-LightBloom150"
            />
          </button>
        </div>

        <button onTouchStart={downHandler} className="flex justify-center">
          <Icon
            size={62}
            icon="arrowDown"
            className="dark:text-dark-PrimaryLight text-LightBloom150"
          />
        </button>
      </div>
    </div>
  )
}

export default SnakeVer2
