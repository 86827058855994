import { Action } from './actions'
import { State } from './interfaces'

// Use Reducer
export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'CHANGE_TOOLBAR': {
      return {
        ...state,
        showToolbar: action.payload
      }
    }

    case 'SET_STATE': {
      return {
        ...state,
        ...action.payload
      }
    }

    case 'CHANGE_SECTION_VISIBILITY': {
      if (action.payload === 'header') return state

      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          show: !state[action.payload].show
        }
      }
    }

    case 'SET_HEADER': {
      return {
        ...state,
        header: {
          ...state.header,
          ...action.payload
        }
      }
    }

    case 'SET_SUMMARY': {
      return {
        ...state,
        summary: {
          ...state.summary,
          ...action.payload
        }
      }
    }

    case 'SET_EXPERIENCE': {
      return {
        ...state,
        experience: {
          ...state.experience,
          ...action.payload
        }
      }
    }

    case 'SET_SKILLS': {
      return {
        ...state,
        skills: {
          ...state.skills,
          ...action.payload
        }
      }
    }

    case 'SET_EDUCATION': {
      return {
        ...state,
        education: {
          ...state.education,
          ...action.payload
        }
      }
    }

    default: {
      return state
    }
  }
}
