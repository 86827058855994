import { Text, View, StyleSheet } from '@react-pdf/renderer'

//COMPONENT
export default function SectionHeading({ heading }: { heading: string }) {
  // Create styles
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      gap: 4,
      marginTop: 10
    },
    heading: {
      fontSize: 15,
      fontWeight: 700
    },
    line: {
      flex: 1,
      borderStyle: 'solid',
      borderBottom: 1,
      borderColor: '#5d5d5d',
      marginBottom: 5
    }
  })

  return (
    <View style={styles.container}>
      <Text style={styles.heading}>{heading}</Text>
      <Text style={styles.line}></Text>
    </View>
  )
}
