import ArrowBack from './icons/ArrowBack'
import ArrowDown from './icons/ArrowDown'
import ArrowForward from './icons/ArrowForward'
import ArrowUp from './icons/ArrowUp'
import Biotech from './icons/Biotech'
import Book from './icons/Book'
import Close from './icons/Close'
import ContactPage from './icons/ContactPage'
import Copy from './icons/Copy'
import DragIndicator from './icons/DragIndicator'
import Folder from './icons/Folder'
import GitHub from './icons/GitHub'
import Img from './icons/Img'
import Instagram from './icons/Instagram'
import Link from './icons/Link'
import LinkedIn from './icons/LinkedIn'
import Placeholder from './icons/PlaceHolder'
import Puzzle from './icons/Puzzle'
import Smile from './icons/Smile'
import Telegram from './icons/Telegram'
import TripOrigin from './icons/TripOrigin'
import User from './icons/User'
import Carousel from './icons/ViewCarousel'
import Visibility from './icons/Visibility'
import VisibilityOff from './icons/VisibilityOff'
import Add from './icons/Add'
import DeleteOutline from './icons/DeleteOutline'
import Remove from './icons/Remove'
import Download from './icons/Download'
import Save from './icons/Save'
import Description from './icons/Description'
import StarBorder from './icons/StarBorder'
import Rewrite from './icons/Rewrite'
import VerticalAlignBottom from './icons/VerticalAlignBottom'
import VerticalAlignTop from './icons/VerticalAlignTop'
import Delete from './icons/Delete'
import Mode from './icons/Mode'
import Upload from './icons/Upload'
import Check from './icons/Check'

const ICONS = {
  add: Add,
  arrowBack: ArrowBack,
  arrowDown: ArrowDown,
  arrowForward: ArrowForward,
  arrowUp: ArrowUp,
  biotech: Biotech,
  book: Book,
  carousel: Carousel,
  close: Close,
  contactPage: ContactPage,
  copy: Copy,
  check: Check,
  delete: Delete,
  deleteOutline: DeleteOutline,
  description: Description,
  download: Download,
  dragIndicator: DragIndicator,
  folder: Folder,
  github: GitHub,
  img: Img,
  instagram: Instagram,
  link: Link,
  linkedIn: LinkedIn,
  placeholder: Placeholder,
  puzzle: Puzzle,
  remove: Remove,
  rewrite: Rewrite,
  save: Save,
  smile: Smile,
  starBorder: StarBorder,
  telegram: Telegram,
  tripOrigin: TripOrigin,
  user: User,
  upload: Upload,
  verticalAlignBottom: VerticalAlignBottom,
  verticalAlignTop: VerticalAlignTop,
  visibility: Visibility,
  visibilityOff: VisibilityOff,
  mode: Mode
}

export default ICONS

export type IconType = (typeof ICONS)[keyof typeof ICONS]
export type SupportedIcon = keyof typeof ICONS
