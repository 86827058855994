import c from 'clsx'
import { useRef, useState } from 'react'
import Icon from '../../../../../../organisms/Icon'

export default function NewItemButton({
  onNewItemClick,
  placeholder,
  scrollAnimation = true
}: {
  onNewItemClick(value: string): void
  placeholder: string
  scrollDelay?: number
  scrollAnimation?: boolean
}) {
  const [newValue, setNewValue] = useState('')
  const newItemContainer = useRef<HTMLDivElement>(null)

  const handleAddItemFocus = () => {
    setTimeout(() => {
      if (newItemContainer.current) {
        newItemContainer.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    })
  }

  return (
    <div
      className="flex items-center custom-no-select mt-2 w-full"
      ref={newItemContainer}
      tabIndex={0}
      aria-hidden="true"
    >
      <button
        className={c(
          'mr-1 text-nowrap border rounded-md',
          'text-PrimaryDark dark:text-dark-Primary',
          'hover:bg-PrimaryLighter dark:hover:bg-dark-WarningLighter',
          'disabled:text-PrimaryDarkOp40 disabled:dark:text-dark-PrimaryOp50',
          'cursor-pointer disabled:cursor-default disabled:bg-transparent'
        )}
        disabled={!newValue}
        onClick={() => {
          onNewItemClick(newValue)
          setNewValue('')
          scrollAnimation && handleAddItemFocus()
        }}
      >
        <Icon size={16} icon="add" />
      </button>
      <input
        value={newValue}
        placeholder={placeholder}
        className={c(
          'w-full bg-transparent outline-none ml-1',
          'border-b mb-px focus:border-b-2 focus:mb-0',
          'text-Grey dark:text-LightBloom100op75',
          'focus:text-Primary focus:dark:text-dark-Primary',
          'placeholder:text-Grey10 placeholder:dark:text-LightBloom100op50'
        )}
        onChange={({ target }) => setNewValue(target.value)}
        onKeyDown={event => {
          if (event.key === 'Enter' && !!newValue) {
            onNewItemClick(newValue)
            setNewValue('')
            scrollAnimation && handleAddItemFocus()
          }
        }}
      />
    </div>
  )
}
