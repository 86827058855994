import { NOTIFICATION_TYPE, Store } from 'react-notifications-component'

interface ToastProps {
  title: string
  message: string
  type: NOTIFICATION_TYPE | undefined
}

// Type: success, default, warning, info, danger

export const Toast = ({
  title = '',
  message = 'No message',
  type = 'default'
}: ToastProps) => {
  Store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: 'top',
    container: 'top-center',
    dismiss: {
      duration: 2000,
      showIcon: false
    }
  })
}
