import c from 'clsx'
import Modal from '../../../../../../../organisms/Modal'

export interface IDialogMessageProps {
  title: string
  cancel(): void
  accept(): void
  acceptText: string
  acceptColor: string
}

//COMPONENT
export default function DialogMessage({
  title,
  cancel,
  accept,
  acceptText,
  acceptColor
}: IDialogMessageProps) {
  return (
    <Modal.Body className="p-2 flex flex-col items-center gap-6">
      <div className="text-lg text-center">{title}</div>

      <div className="flex gap-10">
        <button
          onClick={cancel}
          className={c(
            'py-1 px-2 w-32 text-base',
            'cursor-pointer border-2 rounded-md',
            'text-DarkGrey dark:text-dark-Black dark:text-opacity-60',
            'hover:bg-White80 dark:hover:bg-dark-PrimaryLighter'
          )}
        >
          Cancel
        </button>

        <button
          onClick={accept}
          className={c(
            'py-1 px-2 w-32 text-base',
            'cursor-pointer border-2 rounded-md',
            acceptColor
          )}
        >
          {acceptText}
        </button>
      </div>
    </Modal.Body>
  )
}
