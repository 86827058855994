import { View, StyleSheet } from '@react-pdf/renderer'
import { ISkills } from '../../interfaces'
import Details from './Details'
import Titles from './Titles'
import SectionHeading from '../_shared/SectionHeading'

//COMPONENT
export default function Skills({ heading, skills }: ISkills) {
  const styles = StyleSheet.create({
    container: {
      marginTop: 4
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      gap: 4,
      marginTop: 9
    }
  })

  return (
    <View style={styles.container}>
      <SectionHeading {...{ heading }} />

      <View style={styles.content}>
        <Titles skills={skills} />
        <Details skills={skills} />
      </View>
    </View>
  )
}
