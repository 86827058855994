export enum SectionName {
  HEADER = 'header',
  SUMMARY = 'summary',
  EXPERIENCE = 'experience',
  SKILLS = 'skills',
  EDUCATION = 'education'
}

export enum SectionTitle {
  header = 'Header',
  summary = 'Summary',
  experience = 'Experience',
  skills = 'Skills',
  education = 'Education'
}

export type JobKeys =
  | 'jobTitle'
  | 'companyName'
  | 'jobLocation'
  | 'startDate'
  | 'endDate'
