import { PDFViewer } from '@react-pdf/renderer'
import { ComponentProps } from '../../../../routes'
import useResume from './useResume'
import PDFDocument from './PdfDocument'
import ConstructorBlock from './ConstructorBlock'
import { useEffect, useState } from 'react'

//COMPONENT
export default function Resume({ navActive }: ComponentProps) {
  const { state, dispatch } = useResume()

  const [showPDF, setShowPDF] = useState(true)
  const refreshPdf = () => {
    setShowPDF(false)
    setTimeout(() => setShowPDF(true), 300)
  }

  useEffect(() => refreshPdf(), [navActive])

  return (
    <div className="h-full lg:flex lg:flex-row overflow-auto">
      <div className="lg:h-full lg:mr-10 lg:w-6/12">
        <ConstructorBlock {...{ state, dispatch, refreshPdf }} />
      </div>
      <div className="hidden mb-4 relative lg:block lg:h-full lg:mb-0 lg:w-6/12">
        {showPDF && (
          <PDFViewer className="h-full w-full" showToolbar={state.showToolbar}>
            <PDFDocument {...state} />
          </PDFViewer>
        )}
      </div>
    </div>
  )
}
