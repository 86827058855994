import c from 'clsx'
import { SVGProps } from 'react'

export default function Add({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="add"
      fill="white"
      className={c(['fill-current '], [className && className])}
      {...props}
    >
      <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
    </svg>
  )
}
