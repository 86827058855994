import c from 'clsx'
import { SVGProps } from 'react'

export default function Remove({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="remove"
      fill="white"
      className={c(['fill-current '], [className && className])}
      {...props}
    >
      <path d="M19 13H5V11H19V13Z" />
    </svg>
  )
}
