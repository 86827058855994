import { View } from '@react-pdf/renderer'
import { IExperience } from '../../interfaces'
import JobHeading from './JobHeading'
import JobResponsibility from './JobResponsibility'
import SectionHeading from '../_shared/SectionHeading'

//COMPONENT
export default function Experience({ heading, jobs }: IExperience) {
  return (
    <View>
      <SectionHeading {...{ heading }} />
      {jobs.map(job => (
        <View key={job.id}>
          <JobHeading {...job} />
          {job.responsibilities.map((responsibility, i) => (
            <JobResponsibility
              key={`Responsibility-${i}`}
              {...{ responsibility }}
            />
          ))}
        </View>
      ))}
    </View>
  )
}
