import c from 'clsx'
import Icon from '../../../../../../../organisms/Icon'
import { ChangeEvent, useState } from 'react'

//COMPONENT
export default function SaveButton({
  value,
  onChange,
  onSave,
  disabled,
  nameExist
}: {
  value: string
  onChange(event: ChangeEvent<HTMLInputElement>): void
  onSave(): void
  disabled: boolean
  nameExist: boolean
}) {
  const [inputActive, setInputActive] = useState(false)
  const [saveButtonFocus, setSaveButtonFocus] = useState(false)

  return (
    <div className="flex relative mt-4 flex-1">
      <div
        className={c(
          'bg-White dark:bg-dark-White flex items-center justify-between custom-no-select w-full',
          'border border-r-0 border-Grey px-2 py-1',
          'rounded-l-md',
          inputActive ? 'border-PrimaryDark dark:border-dark-Primary' : ''
        )}
      >
        <div className="flex w-full">
          <Icon
            icon="add"
            className={c(
              'mr-1',
              inputActive ? 'text-PrimaryDark dark:text-dark-Primary' : ''
            )}
          />
          <input
            value={value}
            placeholder="Enter resume name"
            className={c(
              'bg-transparent outline-none w-full ml-1 mr-2',
              'text-Grey dark:text-LightBloom100op75',
              'focus:text-Primary focus:dark:text-dark-Primary',
              'placeholder:text-LightBloom100 placeholder:dark:text-LightBloom100op50'
            )}
            onChange={onChange}
            onBlur={() => setInputActive(false)}
            onFocus={() => setInputActive(true)}
            onKeyDown={event => {
              if (event.key === 'Enter' && !!value) {
                if (!disabled && !nameExist) {
                  onSave()
                }
              }
            }}
          />
        </div>
      </div>
      <div
        className="h-9"
        onMouseEnter={() => setSaveButtonFocus(true)}
        onMouseLeave={() => setSaveButtonFocus(false)}
      >
        <button
          className={c(
            'h-full flex items-center gap-2 pl-2',
            'text-nowrap cursor-pointer rounded-r-md',
            'border px-1',
            'text-PrimaryDark text-opacity-70 dark:text-dark-PrimaryDark',
            'hover:bg-PrimaryDarkOp20 dark:hover:bg-dark-PrimaryOp20',
            'disabled:text-PrimaryDarkOp40 disabled:dark:text-dark-PrimaryOp50 disabled:cursor-default disabled:bg-transparent disabled:hover:dark:bg-transparent'
          )}
          disabled={disabled || nameExist}
          onClick={onSave}
        >
          Save
          <Icon icon="save" />
        </button>
      </div>

      <div
        className={c(
          'absolute rounded-md',
          'border border-ErrorLight bg-ErrorLighter p-1 dark:bg-White10 dark:text-ErrorLight',
          '-top-8 -right-1 transition-opacity duration-200',
          saveButtonFocus && nameExist
            ? 'visible opacity-100'
            : 'invisible opacity-0'
        )}
      >
        The name already exists
      </div>
    </div>
  )
}
