import { Font } from '@react-pdf/renderer'
import { useEffect, useReducer } from 'react'
import { initialResumeData } from './constants'
import { reducer } from './reducer'

const currentResumeStateString = localStorage.getItem('currentResumeState')
const currentResumeState = currentResumeStateString
  ? JSON.parse(currentResumeStateString)
  : undefined

const initialState = currentResumeState || initialResumeData

const useResume = () => {
  // Init
  useEffect(() => {
    Font.register({
      family: 'Lora',
      fonts: [
        {
          src: require('./PdfDocument/_shared/fonts/Lora-Regular.ttf'),
          fontWeight: 400
        }
      ]
    })

    Font.register({
      family: 'Roboto',
      fonts: [
        {
          src: require('./PdfDocument/_shared/fonts/Roboto-Thin.ttf'),
          fontWeight: 100
        },
        {
          src: require('./PdfDocument/_shared/fonts/Roboto-Light.ttf'),
          fontWeight: 300
        },
        {
          src: require('./PdfDocument/_shared/fonts/Roboto-Regular.ttf'),
          fontWeight: 400
        },
        {
          src: require('./PdfDocument/_shared/fonts/Roboto-Medium.ttf'),
          fontWeight: 500
        },
        {
          src: require('./PdfDocument/_shared/fonts/Roboto-Bold.ttf'),
          fontWeight: 700
        },
        {
          src: require('./PdfDocument/_shared/fonts/Roboto-ThinItalic.ttf'),
          fontWeight: 100,
          fontStyle: 'italic'
        },
        {
          src: require('./PdfDocument/_shared/fonts/Roboto-LightItalic.ttf'),
          fontWeight: 300,
          fontStyle: 'italic'
        }
      ]
    })

    return () => {
      Font.reset()
    }
  }, [])

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    localStorage.setItem('currentResumeState', JSON.stringify(state))
  }, [state])

  return { state, dispatch }
}

export default useResume
