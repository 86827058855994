import c from 'clsx'
import { FC, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import useBreakpoint from '../../lib/hooks/useBreakpoint'
import { rout } from '../../routes'
import { OpenArrow } from '../atoms/OpenArrow'
import Icon from './Icon'

interface NavProps {
  active: boolean
  navHandler: () => void
  routes: rout[]
}

export const Navigation: FC<NavProps> = ({ active, navHandler, routes }) => {
  const history = useHistory()
  const [activeGroup, setActiveGroup] = useState<string[]>([])
  const [url, setUrl] = useState<string>('')
  const [show, setShow] = useState(false)
  const [background, setBackground] = useState(false)

  const { md } = useBreakpoint()

  const activeGroupsHandler = (groupName: string) => {
    if (activeGroup.some(group => group === groupName)) {
      setActiveGroup(prevState => prevState.filter(name => name !== groupName))
    } else {
      setActiveGroup(prevState => [...prevState, groupName])
    }
  }

  useEffect(() => {
    const pathname = history.location.pathname
    const url = `${pathname}${history.location.search}`
    const isValidUrl = routes.some(route => {
      return route.paths.some(path => path.path === url)
    })

    if (isValidUrl) {
      setUrl(url)
      setActiveGroup([pathname])
    } else {
      setUrl('/')
      setActiveGroup(['/'])
    }

    history.listen(urlObj => setUrl(`${urlObj.pathname}${urlObj.search}`))
  }, [])

  useEffect(() => {
    if (active) {
      setShow(true)
      setTimeout(() => {
        setBackground(true)
      }, 100)
    } else {
      setBackground(false)
      setTimeout(() => {
        setShow(false)
      }, 400)
    }
  }, [active])

  const isGroupActive = (group: rout) => {
    return group.paths.some(item => activeGroup.some(i => i === item.path))
  }

  const isGroupHasActiveUrl = (group: rout) => {
    return group.paths.some(path => `${path.path}${path.search || ''}` === url)
  }

  return (
    <>
      {!md && show && (
        <div
          onClick={background ? navHandler : undefined}
          className={c(
            'absolute block w-screen h-screen transition-colors duration-400 z-10',
            [
              background
                ? 'bg-LightBloom100op50 dark:bg-Black50'
                : 'bg-transparent'
            ]
          )}
        />
      )}
      <div
        className={c(
          [
            'absolute transition-all transform min-h-screen w-nav-xl min-w-nav-xl z-40 max-h-full overflow-auto'
          ],
          ['md:relative'],
          ['bg-White shadow-nav'],
          ['dark:bg-dark-White dark:shadow-none'],
          [active ? 'translate-x-0' : 'md:-ml-nav-xl -translate-x-72']
        )}
      >
        <div className="relative h-header-xl bg-LightBloom100 dark:bg-White10" />
        <div className="relative px-2 mt-6">
          {routes.map(group => {
            const groupActive = isGroupActive(group)
            const groupHasActiveUrl = isGroupHasActiveUrl(group)

            return (
              <div key={group.nameGroup}>
                <div
                  onClick={() => activeGroupsHandler(group.paths[0].path)}
                  className={c(
                    [
                      'mb-3 transition h-nav-button-xl rounded-md select-none text-lg font-bold flex items-center justify-between p-2 cursor-pointer'
                    ],
                    ['hover:bg-LightBloom50'],
                    ['dark:hover:bg-dark-LightBloom50'],
                    [
                      groupHasActiveUrl
                        ? 'text-Primary dark:text-dark-Primary '
                        : 'text-LightBloom200 dark:text-White'
                    ],
                    [
                      groupHasActiveUrl &&
                        'bg-LightBloom50 dark:bg-dark-LightBloom50'
                    ]
                  )}
                >
                  <div className="flex h-nav-button-xl items-center ">
                    <Icon className="mr-2" icon={group.icon} />
                    {group.nameGroup}
                  </div>

                  <OpenArrow active={groupActive} current={groupHasActiveUrl} />
                </div>
                {groupActive &&
                  group.paths.map(path => (
                    <Link
                      key={path.name}
                      to={`${path.path}${path.search || ''}`}
                      onClick={() => {
                        if (!md) navHandler()
                      }}
                      className={c(
                        ['my-auto block pl-4 pb-4 text-base'],
                        [
                          `${path.path}${path.search || ''}` === url
                            ? 'text-Primary dark:text-dark-Primary'
                            : 'text-LightBloom150 dark:text-White50'
                        ]
                      )}
                    >
                      •&nbsp;&nbsp;&nbsp;{path.name}
                    </Link>
                  ))}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
