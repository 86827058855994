import { Document, Page, StyleSheet, View } from '@react-pdf/renderer'
import { State } from '../interfaces'
import Experience from './Experience'
import Skills from './Skills'
import Education from './Education'
import Summary from './Summary'
import Header from './Header'
import HeaderBlack from './HeaderBlack'

// Create PDF Document Component
export default function PDFDocument({
  header,
  experience,
  skills,
  education,
  summary
}: State) {
  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      fontFamily: 'Roboto',
      fontWeight: 100,
      paddingTop: 32
    },
    container: {
      marginLeft: 36,
      marginRight: 36
    }
  })

  return (
    <Document title="Your Resume">
      <Page size="A4" style={styles.page}>
        {header.template === 'Light' ? (
          <Header {...header} />
        ) : (
          <HeaderBlack {...header} />
        )}

        <View style={styles.container}>
          {summary.show && <Summary {...summary} />}
          {experience.show && <Experience {...experience} />}
          {skills.show && <Skills {...skills} />}
          {education.show && <Education {...education} />}
        </View>
      </Page>
    </Document>
  )
}
