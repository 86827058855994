import { FC, useState } from 'react'
import { ReactNotifications } from 'react-notifications-component'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Header } from './components/organisms/Header'
import { Navigation } from './components/organisms/Navigation'
import routes, { rout } from './routes'
import { getNavBarState } from './lib/functions/localStorage'

const App: FC = () => {
  const [navActive, setNavActive] = useState<boolean>(getNavBarState())
  const paths = routes.map((rout: rout) => rout.paths).flat(1)

  const navHandler = () => {
    setNavActive(prevState => !prevState)
    localStorage.setItem('navBar', navActive ? 'NotActive' : 'Active')
  }

  /* iOS re-orientation fix */
  if (
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i)
  ) {
    /* iOS hides Safari address bar */
    window.addEventListener('load', function () {
      setTimeout(function () {
        window.scrollTo(0, 1)
      }, 1000)
    })
  }

  return (
    <BrowserRouter>
      <ReactNotifications />
      <div className="flex max-h-screen bg-LightBloom50 dark:bg-darkModeBg">
        <Navigation
          routes={routes}
          active={navActive}
          navHandler={navHandler}
        />
        <div className="flex-1 overflow-auto">
          <Header navHandler={navHandler} />
          <div className="h-content-xl p-4 lg:p-9">
            <Switch>
              {paths.map(path => (
                <Route
                  exact
                  key={path.path}
                  path={path.path}
                  render={props => (
                    <path.Component {...props} navActive={navActive} />
                  )}
                />
              ))}
              <Redirect
                to={{
                  pathname: '/'
                }}
              />
            </Switch>
          </div>
        </div>
      </div>

      <div id="modal-root"></div>
      <div className="h-px md:hidden"></div>
    </BrowserRouter>
  )
}

export default App
