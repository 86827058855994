import c from 'clsx'
import { SVGProps } from 'react'

export default function DeleteOutline({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="delete-outline"
      fill="white"
      className={c(['fill-current '], [className && className])}
      {...props}
    >
      <path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z" />
    </svg>
  )
}
