import c from 'clsx'
import Icon from '../../../../../../organisms/Icon'
import { NotificationModal } from '../../../../../../organisms/NotificationModal'
import { useRef, useState } from 'react'
import {
  exportToJson,
  getNewId
} from '../../../../../../../lib/functions/common'
import Modal from '../../../../../../organisms/Modal'
import SaveButton from './components/SaveButton'
import ResumeButton from './components/ResumeButton'
import DialogMessage, { IDialogMessageProps } from './components/DialogMessage'
import { Toast } from '../../../../../../atoms/Toast'
import { isValidJsonData } from './components/utils'
import { Action } from '../../../actions'
import { SavedResume, State } from '../../../interfaces'

//COMPONENT
export default function SavePDFModal({
  state,
  dispatch,
  active,
  onClose,
  refreshPdf
}: {
  state: State
  dispatch: React.Dispatch<Action>
  active: boolean
  onClose(): void
  refreshPdf(): void
}) {
  const fileInputRef = useRef<null | HTMLInputElement>(null)
  const resumes = localStorage.getItem('resumes')
  const [resumesState, setResumesState] = useState<SavedResume[]>(
    resumes ? JSON.parse(resumes) : []
  )

  const [newResumeName, setNewResumeName] = useState('')
  const [dialogMessage, setDialogMessage] = useState<
    IDialogMessageProps | undefined
  >(undefined)

  const handleSaveResume = () => {
    setResumesState(prevState => {
      const updatedResumes = [
        ...prevState,
        {
          id: getNewId(resumesState),
          resumeName: newResumeName,
          data: state
        }
      ]

      localStorage.setItem('resumes', JSON.stringify(updatedResumes))
      setNewResumeName('')

      return updatedResumes
    })
  }

  const handleExportResume = (resumeName: string) => {
    exportToJson(state, resumeName)
  }

  const handleOverwriteResume = (id: number, resumeName: string) => {
    setResumesState(prevState => {
      const updatedResumes = prevState.map(item =>
        item.id === id ? { ...item, data: state } : item
      )

      localStorage.setItem('resumes', JSON.stringify(updatedResumes))

      return updatedResumes
    })

    setDialogMessage(undefined)
    Toast({
      title: 'Success',
      message: `You have successfully rewritten '${resumeName}' resume`,
      type: 'success'
    })
  }

  const handleDeleteResume = (id: number, resumeName: string) => {
    setResumesState(prevState => {
      const updatedResumes = prevState.filter(item => item.id !== id)

      localStorage.setItem('resumes', JSON.stringify(updatedResumes))

      return updatedResumes
    })

    setDialogMessage(undefined)
    Toast({
      title: 'Success',
      message: `You have successfully deleted '${resumeName}' resume`,
      type: 'success'
    })
  }

  function nameCheck(resumes: SavedResume[], fileName: string): string {
    if (resumes.some(file => file.resumeName === fileName)) {
      return nameCheck(resumes, `${fileName} New`)
    }

    return fileName
  }

  const handleImportJsonFile = (fileName: string, jsonData: State) => {
    setResumesState(prevState => {
      const updatedResumes = [
        ...prevState,
        {
          id: getNewId(resumesState),
          resumeName: nameCheck(prevState, fileName),
          data: jsonData
        }
      ]

      localStorage.setItem('resumes', JSON.stringify(updatedResumes))
      setNewResumeName('')

      return updatedResumes
    })
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : undefined

    if (file) {
      const reader = new FileReader()

      reader.onload = e => {
        try {
          const jsonData = JSON.parse(e.target?.result as string)
          if (isValidJsonData(jsonData)) {
            handleImportJsonFile(file.name.replace('.json', ''), jsonData)
          } else {
            Toast({
              title: 'Error',
              message: 'Wrong JSON object',
              type: 'danger'
            })
          }
        } catch (error) {
          Toast({
            title: 'Error',
            message: 'Problem importing JSON object',
            type: 'danger'
          })
        }

        event.target.value = ''
      }

      reader.readAsText(file)
    }
  }

  const handleRenameResume = (resumeName: string, resumeId: number) => {
    if (resumesState.some(resume => resume.resumeName === resumeName)) {
      return Toast({
        title: 'Warning',
        message: 'This name already exists',
        type: 'warning'
      })
    }

    setResumesState(prevState => {
      const updatedResumes = prevState.map(resume =>
        resume.id === resumeId ? { ...resume, resumeName } : resume
      )

      localStorage.setItem('resumes', JSON.stringify(updatedResumes))

      return updatedResumes
    })
  }

  const handleSelectResume = (resumeId: number) => {
    const newState = resumesState.find(resume => resume.id === resumeId)

    if (newState) {
      refreshPdf()

      dispatch({
        type: 'SET_STATE',
        payload: newState.data
      })

      onClose()

      Toast({
        title: 'Success',
        message: `You have selected "${newState.resumeName}" resume`,
        type: 'success'
      })
    }
  }

  return (
    <NotificationModal active={active}>
      <Modal.Wrapper className="w-modal">
        <Modal.Header
          className="h-12"
          leftBox="Manage Resume"
          rightBox={
            <button
              onClick={() => {
                onClose()
                setDialogMessage(undefined)
              }}
              className="hover:bg-LightBloom100 rounded-md hover:transition-colors duration-400 dark:bg-White20"
            >
              <Icon icon="close" className="ml-auto mr-auto" />
            </button>
          }
        />
        {dialogMessage ? (
          <DialogMessage {...dialogMessage} />
        ) : (
          <Modal.Body className="p-2">
            {resumesState.map(({ id, resumeName }) => (
              <ResumeButton
                key={id}
                name={resumeName}
                handleRename={newName => {
                  handleRenameResume(newName, id)
                }}
                handleSelect={() => {
                  handleSelectResume(id)
                }}
                handleExport={() => handleExportResume(resumeName)}
                handleOverwrite={() => {
                  setDialogMessage({
                    title: `Are you sure you want to overwrite "${resumeName}" resume?`,
                    cancel: () => {
                      setDialogMessage(undefined)
                    },
                    accept: () => handleOverwriteResume(id, resumeName),
                    acceptText: 'Overwrite',
                    acceptColor:
                      'text-WarningDarker hover:bg-WarningLighter dark:text-opacity-70 dark:hover:bg-WarningDarker dark:hover:bg-opacity-20'
                  })
                }}
                handleDelete={() => {
                  setDialogMessage({
                    title: `Are you sure you want to delete "${resumeName}" resume?`,
                    cancel: () => {
                      setDialogMessage(undefined)
                    },
                    accept: () => handleDeleteResume(id, resumeName),
                    acceptText: 'Delete',
                    acceptColor:
                      'text-ErrorDark hover:bg-ErrorLighter dark:text-opacity-70 dark:hover:bg-ErrorDark dark:hover:bg-opacity-20'
                  })
                }}
              />
            ))}
            <div className="flex gap-6">
              <SaveButton
                value={newResumeName}
                onChange={({ target }) => {
                  setNewResumeName(target.value)
                }}
                onSave={handleSaveResume}
                disabled={!newResumeName}
                nameExist={resumesState.some(
                  ({ resumeName }) => resumeName === newResumeName
                )}
              />

              <input
                type="file"
                accept=".json"
                ref={fileInputRef}
                onChange={handleFileChange}
                className="hidden"
              />
              <button
                className={c(
                  'flex items-center gap-2 pl-2 pr-3 mt-4',
                  'text-nowrap cursor-pointer rounded-md',
                  'border px-1',
                  'text-PrimaryDark text-opacity-70 dark:text-dark-PrimaryDark',
                  'hover:bg-PrimaryDarkOp20 dark:hover:bg-dark-PrimaryOp20',
                  'disabled:text-PrimaryDarkOp40 disabled:dark:text-dark-PrimaryOp50 disabled:cursor-default disabled:bg-transparent disabled:hover:dark:bg-transparent'
                )}
                onClick={() => {
                  fileInputRef.current?.click()
                }}
              >
                <Icon size={24} icon="upload" />
                Import JSON
              </button>
            </div>
          </Modal.Body>
        )}
      </Modal.Wrapper>
    </NotificationModal>
  )
}
