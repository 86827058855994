import c from 'clsx'
import { SVGProps } from 'react'

export default function Upload({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="upload"
      fill="white"
      className={c(['fill-current'], [className && className])}
      {...props}
    >
      <path
        opacity="0.3"
        d="M9.83008 8.00008H11.0001V14.0001H13.0001V8.00008H14.1701L12.0001 5.83008L9.83008 8.00008Z"
      />
      <path d="M12 3L5 10H9V16H15V10H19L12 3ZM13 8V14H11V8H9.83L12 5.83L14.17 8H13ZM5 18H19V20H5V18Z" />
    </svg>
  )
}
