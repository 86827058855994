import { View, StyleSheet } from '@react-pdf/renderer'
import { IEducation } from '../../interfaces'
import School from './School'
import SectionHeading from '../_shared/SectionHeading'

//COMPONENT
export default function Education({ schools, heading }: IEducation) {
  const styles = StyleSheet.create({
    container: {
      marginTop: 4
    }
  })
  return (
    <View style={styles.container}>
      <SectionHeading {...{ heading }} />

      {schools.map(school => (
        <School key={school.id} {...school} />
      ))}
    </View>
  )
}
