export const getNavBarState = () => {
  const savedState = localStorage.getItem('navBar')
  if (savedState) {
    return savedState === 'Active' ? true : false
  } else {
    localStorage.setItem(
      'navBar',
      window.innerWidth > 1280 ? 'Active' : 'NotActive'
    )
    return window.innerWidth > 1280
  }
}
