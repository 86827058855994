import c from 'clsx'
import Icon from '../../../../../../organisms/Icon'

export default function AddRemoveItem({
  handleAddItem,
  handleRemoveItem,
  itemName,
  disableRemove
}: {
  handleAddItem(): void
  handleRemoveItem(): void
  itemName: string
  disableRemove: boolean
}) {
  return (
    <div className="flex justify-between flex-wrap gap-2">
      <button
        className={c(
          'flex items-center gap-1 py-1 px-4',
          'text-nowrap cursor-pointer border rounded-md',
          'text-PrimaryDark dark:text-dark-Primary',
          'hover:bg-PrimaryDark dark:hover:bg-dark-Primary hover:bg-opacity-25',
          'disabled:opacity-40 disabled:cursor-default disabled:bg-transparent'
        )}
        onClick={handleAddItem}
      >
        Add {itemName}
        <Icon size={16} icon="add" />
      </button>
      <button
        className={c(
          'flex items-center gap-1 py-1 px-4',
          'text-nowrap cursor-pointer border rounded-md',
          'text-ErrorLight dark:text-Error',
          'hover:bg-ErrorLight dark:hover:bg-Error hover:bg-opacity-25',
          'disabled:opacity-40 disabled:cursor-default disabled:bg-transparent'
        )}
        disabled={disableRemove}
        onClick={handleRemoveItem}
      >
        Remove {itemName}
        <Icon size={16} icon="deleteOutline" />
      </button>
    </div>
  )
}
