import Title from './Title'
import ContactsBar from './ContactsBar'
import { IHeader } from '../../interfaces'

//COMPONENT
export default function Header({
  fullName,
  email,
  phone,
  address,
  website
}: IHeader) {
  return (
    <>
      <Title fullName={fullName} />
      <ContactsBar
        email={email}
        phone={phone}
        address={address}
        website={website}
      />
    </>
  )
}
