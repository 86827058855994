import { Text, StyleSheet, View } from '@react-pdf/renderer'
import { ISkill } from '../../interfaces'

//COMPONENT
export default function Titles({ skills }: { skills: ISkill[] }) {
  // Create styles
  const styles = StyleSheet.create({
    container: {
      display: 'flex'
    },
    title: {
      fontSize: 9,
      fontWeight: 500,
      height: 12,
      color: '#333333'
    }
  })

  return (
    <View style={styles.container}>
      {skills.map(({ title }, index) => (
        <Text key={index} style={styles.title}>
          {title}:
        </Text>
      ))}
    </View>
  )
}
