import { Text, View, StyleSheet } from '@react-pdf/renderer'

//COMPONENT
export default function LeftContainer({
  fullName,
  position
}: {
  fullName: string
  position: string
}) {
  const firstWordExtractor = /^(\S+)(?:\s+(.*))?$/
  const match = fullName.match(firstWordExtractor)

  const firstWord = match ? match[1] : ''
  const remainingWords = match ? match[2] : ''

  // Create styles
  const styles = StyleSheet.create({
    container: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingTop: 24
    },
    logo: { marginBottom: 24 },
    fullName: {
      color: 'white',
      fontSize: 32,
      fontWeight: 400
    },
    position: {
      color: '#D0D0D3',
      fontWeight: 400,
      marginTop: 16,
      marginBottom: 16
    },
    imagesContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 8,
      marginBottom: 16
    },
    imageBox: {
      width: 50,
      height: 60,
      justifyContent: 'center',
      alignItems: 'center'
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    }
  })

  return (
    <View style={styles.container}>
      <Text style={styles.fullName}>{firstWord}</Text>
      <Text style={styles.fullName}>{remainingWords}</Text>

      <Text style={styles.position}>{position}</Text>
    </View>
  )
}
