import { allLinks } from './data'
import { LinkOffset, LinkOffsetsAndCounter, Sort } from './interfaces'

export const getLinkOffsetsAndCount = (
  sortType: Sort,
  activeCategories: string[]
): LinkOffsetsAndCounter => {
  let offset = 20
  let visibleLinkCounter = 0
  const linkOffsets: LinkOffset = {}

  //if we are going to sort we need to copy the array, otherwise just create a link
  const allLinksArray = sortType === 'Category' ? [...allLinks] : allLinks

  //sorter by category
  if (sortType === 'Category') {
    allLinksArray.sort((a, b) => {
      if (a.category > b.category) return 1
      if (a.category < b.category) return -1
      return 0
    })
  }

  //create each link an offset
  allLinksArray.forEach(link => {
    if (activeCategories.some(cat => cat === link.category)) {
      linkOffsets[link.name] = offset
      visibleLinkCounter += 1
      offset += 46
    } else {
      linkOffsets[link.name] = -50
    }
  })

  return {
    linkOffsets: linkOffsets,
    visibleLinkCounter: visibleLinkCounter
  }
}
