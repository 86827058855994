import c from 'clsx'
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { IProject } from '../../../../lib/models'
import Icon from '../../../organisms/Icon'
import Modal from '../../../organisms/Modal'

interface ItemModalProps {
  item: IProject | null
  onClose(): void
  md: boolean
  lg: boolean
}

const ItemModal = ({ item, md, lg, onClose }: ItemModalProps): JSX.Element => (
  <div
    className={c(
      [
        'absolute top-0 left-0 w-full h-screen z-40 flex justify-center items-center transition-colors duration-200 '
      ],
      [item ? 'visible ' : 'invisible'],
      [item ? 'bg-LightBloom100op50' : 'bg-transparent']
    )}
    onClick={e => {
      const tesId = e.target as HTMLInputElement

      if (tesId.id === 'modal-wrapper') return onClose()
    }}
    id="modal-wrapper"
  >
    {item && (
      <Modal.Wrapper className="h-full lg:w-project-modal lg:h-project-modal rounded-none lg:rounded-md">
        <Modal.Header
          className="lg:p-6 lg:pb-4 dark:bg-dark-LightBloom50"
          leftBox={
            <div className="flex">
              <div
                className="h-20 w-20 min-w-20 flex justify-center items-center rounded-xl p-2"
                style={item.bgColor ? { backgroundColor: item.bgColor } : {}}
              >
                <img src={`./portfolio/${item.icon}.png`} alt="" />
              </div>
              <div className="ml-4 mt-auto mb-auto">
                <div className="text-lg lg:text-xl">{item.title}</div>
                <div className="text-xs lg:text-sm max-w-sm text-Grey font-light">
                  {item.subtitle}
                </div>
              </div>
            </div>
          }
          rightBox={
            <button
              onClick={onClose}
              className="h-7 w-7 bg-LightBloom100op50 hover:bg-LightBloom100 rounded-2xl hover:transition-colors duration-400 dark:bg-White20"
            >
              <Icon icon="close" className="ml-auto mr-auto" />
            </button>
          }
        />

        <Modal.Body className="max-h-project-modal-mobile lg:max-h-project-modal pt-2 lg:pt-4 dark:bg-dark-LightBloom50 ">
          <div className="text-DarkGrey tracking-normal ml-4 lg:ml-6 dark:text-White">
            TECHNOLOGY
          </div>
          <div className="flex mt-2 ml-4 lg:ml-6 max-w-full overflow-auto pb-2">
            {item.technology.map(tech => (
              <div
                key={tech}
                className="px-3 py-2 border border-LightBloom100 mr-2 rounded-md dark:border-opacity-40 whitespace-nowrap"
              >
                {tech}
              </div>
            ))}
          </div>

          <Swiper
            className="mt-5"
            spaceBetween={lg ? 20 : 12}
            slidesOffsetBefore={lg ? 24 : 16}
            slidesPerView={lg ? 1.25 : md ? 1.5 : 1.1}
            mousewheel={{
              forceToAxis: true
            }}
            modules={[Navigation]}
            navigation
          >
            {item.images.map((image, index) => (
              <SwiperSlide
                key={`image-${index}`}
                className="cursor-grab rounded-2xl h-60 md:h-110 overflow-hidden flex items-center justify-center select-none bg-LightBloom100op50 dark:bg-White20"
              >
                <img
                  src={image}
                  alt={`${index}_${item.title}`}
                  className="max-h-full p-4"
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <p className="mt-6 mb-6 text-base lg:text-lg max-w-2xl whitespace-pre-wrap ml-4 lg:ml-6">
            {item.description}
          </p>

          <div className="flex ml-4 lg:ml-6">
            {item.github && (
              <a
                className={c(
                  [
                    'mr-2 lg:mr-4 rounded-md font-semibold block text-Black text-center p-2.5 bg-LightBloom100op50 dark:bg-White20 dark:text-White mb-10'
                  ],
                  [
                    'bg-LightBloom100op50 hover:bg-LightBloom100op75 dark:hover:bg-White30'
                  ],
                  ['transition-colors duration-200']
                )}
                href={item.github}
                target="_blank"
              >
                <Icon icon="github" />
              </a>
            )}

            {item.link && (
              <a
                className={c(
                  [
                    'w-44 rounded-md font-semibold block text-Black text-center py-2.5 bg-LightBloom100op50 dark:bg-White20 dark:text-White mb-10'
                  ],
                  [
                    'bg-LightBloom100op50 hover:bg-LightBloom100op75 dark:hover:bg-White30'
                  ],
                  ['transition-colors duration-200']
                )}
                href={item.link}
                target="_blank"
              >
                Visit
              </a>
            )}
          </div>
        </Modal.Body>
      </Modal.Wrapper>
    )}
  </div>
)

export default ItemModal
