import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { ISummary } from '../../interfaces'
import SectionHeading from '../_shared/SectionHeading'

//COMPONENT
export default function Summary({ heading, summary }: ISummary) {
  const styles = StyleSheet.create({
    summaryBox: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 3
    },
    summary: {
      fontSize: 9,
      fontWeight: 500,
      color: '#333333'
    }
  })
  return (
    <View>
      <SectionHeading {...{ heading }} />

      <View style={styles.summaryBox}>
        <Text style={styles.summary}>{summary}</Text>
      </View>
    </View>
  )
}
