import c from 'clsx'

import { SectionTitle, SectionName } from '../interfaces'
import { Action } from '../../actions'
import Icon from '../../../../../organisms/Icon'
import { State } from '../../interfaces'

//COMPONENT
export default function Structure({
  state,
  dispatch,
  activeTab,
  setActiveTab
}: {
  state: State
  dispatch: React.Dispatch<Action>
  activeTab: SectionName
  setActiveTab: React.Dispatch<React.SetStateAction<SectionName>>
}) {
  return (
    <>
      {[
        SectionName.HEADER,
        SectionName.SUMMARY,
        SectionName.EXPERIENCE,
        SectionName.SKILLS,
        SectionName.EDUCATION
      ].map(section => {
        return (
          <div
            key={section}
            className={c(
              'flex items-center',
              'border-b border-Black border-opacity-10 dark:border-dark-LightBloom50',
              'hover:bg-LightBloom50 dark:hover:bg-White10'
            )}
          >
            {section !== SectionName.HEADER ? (
              <button
                className="flex justify-center items-center ml-3 h-10"
                onClick={() =>
                  dispatch({
                    type: 'CHANGE_SECTION_VISIBILITY',
                    payload: section
                  })
                }
              >
                <Icon
                  size={20}
                  icon={state[section].show ? 'visibility' : 'visibilityOff'}
                  className={
                    state[section].show
                      ? 'text-PrimaryLight dark:text-dark-PrimaryDark'
                      : 'text-LightBloom150'
                  }
                />
              </button>
            ) : (
              <div className="w-8"></div>
            )}
            <button
              key={section}
              className={c(
                'flex items-center font-bold text-sm flex-1 h-10 ml-3',
                activeTab === section
                  ? 'text-Primary dark:text-dark-Primary'
                  : ''
              )}
              onClick={() => setActiveTab(section)}
            >
              <div>{SectionTitle[section]}</div>
            </button>
          </div>
        )
      })}
    </>
  )
}
