import { Reorder } from 'framer-motion'
import { useState, useCallback, useEffect, useRef } from 'react'
import { JobKeys } from '../interfaces'
import { IExperience, IResponsibility } from '../../interfaces'
import { getNewId } from '../../../../../../lib/functions/common'
import { Action } from '../../actions'
import ReorderItem from './_shared/ReorderItem'
import InputContent from './_shared/InputContent'
import NewItemButton from './_shared/NewItemButton'
import AddRemoveItem from './_shared/AddRemoveItem'

export default function Experience({
  experience,
  dispatch
}: {
  experience: IExperience
  dispatch: React.Dispatch<Action>
}) {
  const [animation, setAnimation] = useState(true)
  const [headingData, setHeadingData] = useState(experience.heading)
  const [jobsData, setJobsData] = useState(experience.jobs)

  const newJobContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setHeadingData(experience.heading)
    setJobsData(experience.jobs)
  }, [experience])

  const handleAddJobFocus = () => {
    setTimeout(() => {
      if (newJobContainer.current) {
        newJobContainer.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    })
  }

  const updateJobState = useCallback(() => {
    dispatch({
      type: 'SET_EXPERIENCE',
      payload: { heading: headingData, jobs: jobsData }
    })
  }, [headingData, jobsData, dispatch])

  const handleInputChange = ({
    jobId,
    inputName,
    value
  }: {
    jobId: number
    inputName: JobKeys
    value: string
  }) => {
    setJobsData(prevState =>
      prevState.map(job =>
        job.id === jobId ? { ...job, [inputName]: value } : job
      )
    )
  }

  const handleResponsibilitiesChange = (
    jobId: number,
    responsibilityId: number,
    value: string
  ) => {
    setJobsData(prevState =>
      prevState.map(job =>
        job.id === jobId
          ? {
              ...job,
              responsibilities: job.responsibilities.map(responsibility =>
                responsibility.id === responsibilityId
                  ? { ...responsibility, value }
                  : responsibility
              )
            }
          : job
      )
    )
  }

  const handleReorderGroup = (
    jobId: number,
    responsibilities: IResponsibility[]
  ) => {
    setJobsData(prevState =>
      prevState.map(job =>
        job.id === jobId ? { ...job, responsibilities } : job
      )
    )
  }

  const addNewResponsibility = (id: number, value: string) => {
    setJobsData(prevState => {
      const updatedJobs = prevState.map(job =>
        job.id === id
          ? {
              ...job,
              responsibilities: [
                ...job.responsibilities,
                { id: getNewId(job.responsibilities), value }
              ]
            }
          : job
      )

      setTimeout(() => {
        dispatch({
          type: 'SET_EXPERIENCE',
          payload: { jobs: updatedJobs }
        })
      })

      return updatedJobs
    })
  }

  const handleAddJob = () => {
    setJobsData(prevState => [
      ...prevState,
      {
        id: getNewId(prevState),
        jobTitle: '',
        companyName: '',
        jobLocation: '',
        startDate: '',
        endDate: '',
        responsibilities: []
      }
    ])

    handleAddJobFocus()
  }

  const handleRemoveJob = () => {
    setAnimation(false)

    setJobsData(prevState => {
      const updatedJobs = [...prevState]
      updatedJobs.pop()

      setTimeout(
        () =>
          dispatch({
            type: 'SET_EXPERIENCE',
            payload: { jobs: updatedJobs }
          }),
        300
      )

      return updatedJobs
    })

    setTimeout(() => setAnimation(true), 300)
  }

  const handleRemoveResponsibility = (
    jobId: number,
    responsibilityId: number
  ) => {
    setAnimation(false)

    setJobsData(prevState => {
      const updatedJobs = prevState.map(job =>
        job.id === jobId
          ? {
              ...job,
              responsibilities: job.responsibilities.filter(
                responsibility => responsibility.id !== responsibilityId
              )
            }
          : job
      )

      setTimeout(
        () =>
          dispatch({
            type: 'SET_EXPERIENCE',
            payload: { jobs: updatedJobs }
          }),
        300
      )

      return updatedJobs
    })

    setTimeout(() => setAnimation(true), 300)
  }

  return (
    <>
      <InputContent
        className="mb-8"
        label="Section Heading"
        placeholder="Experience"
        value={headingData}
        onChange={({ target }) => setHeadingData(target.value)}
        onBlur={updateJobState}
      />

      {jobsData.map(
        ({
          id,
          jobTitle,
          companyName,
          jobLocation,
          startDate,
          endDate,
          responsibilities
        }) => (
          <div key={id} className="mb-6">
            <div className="w-full h-2.5 bg-PrimaryLight10 dark:bg-Grey mb-2"></div>

            {[
              {
                label: 'Job Title',
                value: jobTitle,
                name: 'jobTitle',
                placeholder: 'Senior Frontend Developer'
              },
              {
                label: 'Company Name',
                value: companyName,
                name: 'companyName',
                placeholder: 'Google'
              },
              {
                label: 'Job Location',
                value: jobLocation,
                name: 'jobLocation',
                placeholder: 'Tallinn, Harjumaa, Estonia'
              },
              {
                label: 'Start Date',
                value: startDate,
                name: 'startDate',
                placeholder: 'Aug 2021'
              },
              {
                label: 'End Date',
                value: endDate,
                name: 'endDate',
                placeholder: 'Aug 2022 / Present'
              }
            ].map(({ label, value, name, placeholder }) => (
              <InputContent
                className="mb-4"
                key={name}
                label={label}
                placeholder={placeholder}
                value={value}
                onChange={({ target }) =>
                  handleInputChange({
                    jobId: id,
                    inputName: name as JobKeys,
                    value: target.value
                  })
                }
                onBlur={updateJobState}
              />
            ))}

            <div className="text-sm font-semibold dark:text-White50 mt-6">
              Job Responsibilities
            </div>
            <Reorder.Group
              axis="y"
              onReorder={items => handleReorderGroup(id, items)}
              values={responsibilities}
            >
              {responsibilities.map(responsibility => (
                <ReorderItem
                  key={responsibility.id}
                  item={responsibility}
                  animation={animation}
                  onChange={({ target }) =>
                    handleResponsibilitiesChange(
                      id,
                      responsibility.id,
                      target.value
                    )
                  }
                  onRemove={() =>
                    handleRemoveResponsibility(id, responsibility.id)
                  }
                  onComplete={updateJobState}
                />
              ))}
            </Reorder.Group>
            <NewItemButton
              onNewItemClick={value => {
                addNewResponsibility(id, value)
              }}
              placeholder="Add new responsibility"
            />
          </div>
        )
      )}

      <div ref={newJobContainer} tabIndex={0} aria-hidden="true">
        <AddRemoveItem
          handleAddItem={handleAddJob}
          handleRemoveItem={handleRemoveJob}
          itemName="Job"
          disableRemove={!jobsData.length}
        />
      </div>
    </>
  )
}
