import c from 'clsx'

export default function InputContent({
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  textarea,
  rows,
  className
}: {
  label: string
  placeholder: string
  value?: string | number
  onChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
  onBlur(): void
  textarea?: boolean
  rows?: number
  className?: string
}) {
  return (
    <div className={className}>
      <div className="mb-1 text-sm font-semibold dark:text-White50">
        {label}
      </div>
      {textarea ? (
        <textarea
          value={value}
          className={c(
            'w-full bg-transparent outline-none p-2 border rounded-md',
            'text-Grey dark:text-LightBloom100op75',
            'focus:text-Primary focus:dark:text-dark-Primary',
            'placeholder:text-LightBloom100 placeholder:dark:text-LightBloom100op50'
          )}
          placeholder={placeholder}
          rows={rows}
          onChange={onChange}
          onBlur={onBlur}
        />
      ) : (
        <input
          value={value}
          className={c(
            'w-full bg-transparent outline-none',
            'border-b mb-px focus:border-b-2 focus:mb-0',
            'text-Grey dark:text-LightBloom100op75',
            'focus:text-Primary focus:dark:text-dark-Primary',
            'placeholder:text-LightBloom100 placeholder:dark:text-LightBloom100op50'
          )}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
    </div>
  )
}
