import { SVGProps } from 'react'
import c from 'clsx'

export default function VerticalAlignBottom({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="vertical-align-bottom"
      fill="white"
      className={c(['fill-current'], [className && className])}
      {...props}
    >
      <path d="M11 3V13H8L12 17L16 13H13V3H11ZM4 19H20V21H4V19Z" />
    </svg>
  )
}
