import {
  IDetail,
  IEducation,
  IExperience,
  IHeader,
  IJob,
  IResponsibility,
  ISchool,
  ISkill,
  ISkills,
  ISummary,
  State
} from '../../../../interfaces'

function isIHeader(header: IHeader) {
  return (
    typeof header === 'object' &&
    header !== null &&
    'template' in header &&
    'fullName' in header &&
    'position' in header &&
    'email' in header &&
    'phone' in header &&
    'address' in header &&
    'website' in header &&
    typeof header.template === 'string' &&
    typeof header.fullName === 'string' &&
    typeof header.position === 'string' &&
    typeof header.email === 'string' &&
    typeof header.phone === 'string' &&
    typeof header.address === 'string' &&
    typeof header.website === 'string'
  )
}

function isISummary(summary: ISummary) {
  return (
    typeof summary === 'object' &&
    summary !== null &&
    typeof summary.heading === 'string' &&
    typeof summary.show === 'boolean' &&
    typeof summary.summary === 'string'
  )
}

function isIResponsibility(responsibility: IResponsibility) {
  return (
    typeof responsibility === 'object' &&
    responsibility !== null &&
    typeof responsibility.id === 'number' &&
    typeof responsibility.value === 'string'
  )
}

function isIJob(job: IJob) {
  return (
    typeof job === 'object' &&
    job !== null &&
    typeof job.id === 'number' &&
    typeof job.jobTitle === 'string' &&
    typeof job.companyName === 'string' &&
    typeof job.jobLocation === 'string' &&
    typeof job.startDate === 'string' &&
    typeof job.endDate === 'string' &&
    Array.isArray(job.responsibilities) &&
    job.responsibilities.every(isIResponsibility)
  )
}

function isIExperience(experience: IExperience) {
  return (
    typeof experience === 'object' &&
    experience !== null &&
    typeof experience.heading === 'string' &&
    typeof experience.show === 'boolean' &&
    Array.isArray(experience.jobs) &&
    experience.jobs.every(isIJob)
  )
}

function isIDetail(detail: IDetail) {
  return (
    typeof detail === 'object' &&
    detail !== null &&
    typeof detail.id === 'number' &&
    typeof detail.value === 'string'
  )
}

function isISkill(skill: ISkill) {
  return (
    typeof skill === 'object' &&
    skill !== null &&
    typeof skill.id === 'number' &&
    typeof skill.title === 'string' &&
    Array.isArray(skill.details) &&
    skill.details.every(isIDetail)
  )
}

function isISkills(skills: ISkills) {
  return (
    typeof skills === 'object' &&
    skills !== null &&
    typeof skills.heading === 'string' &&
    typeof skills.show === 'boolean' &&
    Array.isArray(skills.skills) &&
    skills.skills.every(isISkill)
  )
}

function isISchool(school: ISchool) {
  return (
    typeof school === 'object' &&
    school !== null &&
    typeof school.id === 'number' &&
    typeof school.schoolName === 'string' &&
    typeof school.schoolLocation === 'string' &&
    typeof school.degree === 'string' &&
    typeof school.major === 'string' &&
    typeof school.startDate === 'string' &&
    typeof school.endDate === 'string'
  )
}

function isIEducation(education: IEducation) {
  return (
    typeof education === 'object' &&
    education !== null &&
    typeof education.heading === 'string' &&
    typeof education.show === 'boolean' &&
    Array.isArray(education.schools) &&
    education.schools.every(isISchool)
  )
}

export const isValidJsonData = (state: State) => {
  if (typeof state !== 'object' || state === null) return false

  if ('showToolbar' in state && typeof state.showToolbar !== 'boolean')
    return false

  if ('header' in state && typeof state.header !== 'object') return false
  if (!isIHeader(state.header)) return false

  if ('summary' in state && typeof state.summary !== 'object') return false
  if (!isISummary(state.summary)) return false

  if ('experience' in state && typeof state.experience !== 'object')
    return false
  if (!isIExperience(state.experience)) return false

  if ('skills' in state && typeof state.skills !== 'object') return false
  if (!isISkills(state.skills)) return false

  if ('education' in state && typeof state.education !== 'object') return false
  if (!isIEducation(state.education)) return false

  return true
}
