import { ISvg } from './interfaces'

const Clouds = ({ size, style, className }: ISvg): JSX.Element => (
  <svg
    width={size}
    height={size * 0.93}
    viewBox="0 0 40 37"
    fill="white"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38 10.5C38 12.8736 37.2124 15.0632 35.8844 16.8218C35.9602 17.2028 36 17.5967 36 18C36 21.3137 33.3137 24 30 24C29.5839 24 29.1777 23.9576 28.7854 23.877C28.2037 25.6887 26.505 27 24.5 27C23.8125 27 23.161 26.8458 22.5782 26.5702C21.5077 29.7276 18.5192 32 15 32C14.107 32 13.2482 31.8537 12.4463 31.5837C11.163 33.0576 9.20003 34 7 34C3.13401 34 0 31.0899 0 27.5C0 23.9101 3.13401 21 7 21C7.19288 21 7.38393 21.0072 7.57287 21.0215C8.75428 18.0783 11.6344 16 15 16C15.5072 16 16.0033 16.0472 16.4842 16.1374C16.9875 15.7729 17.5496 15.4846 18.1535 15.2895C17.4161 13.8535 17 12.2253 17 10.5C17 4.70101 21.701 0 27.5 0C33.299 0 38 4.70101 38 10.5Z"
      fill="#FBE9C2"
      fillOpacity="0.55"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38 13C38 13.788 37.8987 14.5523 37.7085 15.2807C38.5172 16.3031 39 17.5952 39 19C39 22.3137 36.3137 25 33 25C32.5839 25 32.1777 24.9576 31.7854 24.877C31.2037 26.6887 29.505 28 27.5 28C27.1771 28 26.8622 27.966 26.5586 27.9014C25.4581 29.186 23.8241 30 22 30C21.1534 30 20.3477 29.8247 19.6173 29.5083C18.4274 31.0253 16.5775 32 14.5 32C14.4878 32 14.4757 32 14.4636 31.9999C13.4087 34.3489 10.912 36 8 36C4.13401 36 1 33.0899 1 29.5C1 25.9101 4.13401 23 8 23C8.16527 23 8.3292 23.0053 8.49161 23.0158C9.46735 20.6584 11.79 19 14.5 19C15.6568 19 16.743 19.3022 17.6841 19.8319C18.6728 18.8083 20.0224 18.1355 21.5278 18.0183C20.5631 16.5846 20 14.858 20 13C20 8.02944 24.0294 4 29 4C33.9706 4 38 8.02944 38 13Z"
      fill="#FEF8E8"
      fillOpacity="0.79"
    />
    <circle cx="21" cy="26" r="6" />
    <circle cx="13" cy="28" r="6" />
    <circle cx="26.5" cy="22.5" r="4.5" />
    <circle cx="32" cy="15" r="8" />
    <circle cx="29.5" cy="7.5" r="2.5" />
    <circle cx="33" cy="19" r="6" />
    <circle cx="8" cy="31" r="6" />
  </svg>
)

export default Clouds
