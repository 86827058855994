import { SVGProps } from 'react'
import c from 'clsx'

export default function VerticalAlignTop({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="vertical-align-top"
      fill="white"
      className={c(['fill-current'], [className && className])}
      {...props}
    >
      <path d="M4 3H20V5H4V3ZM8 11H11V21H13V11H16L12 7L8 11Z" />
    </svg>
  )
}
