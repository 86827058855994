import { useState, useCallback, useEffect } from 'react'
import { Action } from '../../actions'
import InputContent from './_shared/InputContent'
import { ISummary } from '../../interfaces'

// COMPONENT
export default function Summary({
  summary,
  dispatch
}: {
  summary: ISummary
  dispatch: React.Dispatch<Action>
}) {
  const [headingData, setHeadingData] = useState(summary.heading)
  const [summaryData, setSummaryData] = useState(summary.summary)

  useEffect(() => {
    setHeadingData(summary.heading)
    setSummaryData(summary.summary)
  }, [summary])

  const updateSummaryData = useCallback(() => {
    dispatch({
      type: 'SET_SUMMARY',
      payload: { heading: headingData, summary: summaryData }
    })
  }, [headingData, summaryData, dispatch])

  return (
    <>
      <InputContent
        className="mb-4"
        label="Section Heading"
        placeholder="Summary"
        value={headingData}
        onChange={e => setHeadingData(e.target.value)}
        onBlur={updateSummaryData}
      />

      <InputContent
        className="mb-4"
        textarea={true}
        rows={10}
        label="Summary"
        placeholder="10 years of experience in front-end development..."
        value={summaryData}
        onChange={e => setSummaryData(e.target.value)}
        onBlur={updateSummaryData}
      />
    </>
  )
}
