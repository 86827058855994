import c from 'clsx'
import ReactDOM from 'react-dom'

interface HeaderProps {
  active: boolean
  className?: string
  children?: JSX.Element
}

export const NotificationModal = ({
  active,
  className,
  children
}: HeaderProps): JSX.Element => {
  const modalRoot = document.getElementById('modal-root') as HTMLElement

  if (modalRoot) {
    return ReactDOM.createPortal(
      <div
        className={c(
          'flex items-center justify-center',
          'w-screen h-screen absolute z-50 top-0 left-0 bg-PrimaryDark ',
          active
            ? 'visible opacity-100 bg-opacity-30 transition-opacity duration-300'
            : 'invisible opacity-0 bg-opacity-0 pointer-events-none',

          className
        )}
      >
        {children}
      </div>,
      modalRoot
    )
  } else {
    return <div></div>
  }
}
