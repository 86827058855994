export function calculateDistance(
  x1: number,
  y1: number,
  x2: number,
  y2: number
): number {
  const deltaX = x2 - x1
  const deltaY = y2 - y1
  const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY)
  return distance
}

export function getNewId(array: { id: number }[]) {
  let highestId = 0

  array.forEach(el => {
    if (el.id > highestId) highestId = el.id
  })

  return highestId + 1
}

export function exportToJson(data: object, filename = 'data.json') {
  const jsonStr = JSON.stringify(data, null, 2)
  const blob = new Blob([jsonStr], { type: 'application/json' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  a.click()
  URL.revokeObjectURL(url)
}

export function isSafari() {
  const userAgent = navigator.userAgent.toLocaleLowerCase()

  return /safari/.test(userAgent) && !/chrome/.test(userAgent)
}

function preventDefault(e: TouchEvent) {
  if (e.cancelable) {
    e.preventDefault()
  }
}

export function disableScroll() {
  document.body.style.overflow = 'hidden'
  window.addEventListener('touchmove', preventDefault, { passive: false })
}

export function enableScroll() {
  document.body.style.overflow = ''
  window.removeEventListener('touchmove', preventDefault)
}
