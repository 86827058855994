import { GAME } from './constants'
import { ICell, IFruit, IGameObject } from './interfaces'

export default class BoardService {
  private ctx: CanvasRenderingContext2D
  private game: IGameObject
  private boardLoaded: boolean
  private cells: ICell[]
  private fruit: IFruit
  private halfCell = GAME.CELL_SIZE / 2

  constructor() {
    this.cells = this.getCells()
    this.fruit = this.getFruit()
  }

  public setGameObj(game: IGameObject) {
    this.ctx = game.ctx
    this.game = game
  }

  public getCells(): ICell[] {
    const cells = []
    const { WIDTH, HEIGHT, CELL_SIZE, CELL_SPACE_BETWEEN } = GAME

    const col = WIDTH / (CELL_SIZE + CELL_SPACE_BETWEEN)
    const row = HEIGHT / (CELL_SIZE + CELL_SPACE_BETWEEN)

    for (let i = 0; i < row; i++) {
      const y = (CELL_SIZE + CELL_SPACE_BETWEEN) * i

      for (let j = 0; j < col; j++) {
        const x = (CELL_SIZE + CELL_SPACE_BETWEEN) * j

        cells.push({
          x,
          y,
          cellSize: CELL_SIZE
        })
      }
    }

    return cells
  }

  public getFruit() {
    const randomIndex = Math.floor(Math.random() * this.cells.length)

    return {
      x: this.cells[randomIndex].x + this.halfCell,
      y: this.cells[randomIndex].y + this.halfCell
    }
  }

  public boardRenderWithAnimation(): Promise<void> {
    this.game.clear()
    this.boardLoaded = false
    let currentCell = 0

    return new Promise(resolve => {
      this.ctx.fillStyle =
        localStorage.theme === 'dark' ? GAME.BOARD_COLOR_DARK : GAME.BOARD_COLOR

      const cellRender = () => {
        if (this.cells[currentCell]) {
          const { x, y, cellSize } = this.cells[currentCell]
          this.ctx.fillRect(x, y, cellSize, cellSize)
          currentCell++
        } else {
          this.boardLoaded = true
        }

        this.game.setProgress(
          Math.floor((currentCell / this.cells.length) * 100)
        )
      }

      const callback = () => {
        if (this.boardLoaded) return resolve()

        let i = 0
        while (i < GAME.BOARD_SPEED) {
          cellRender()
          i++
        }

        this.game.animation(callback)
      }

      this.game.animation(callback)
    })
  }

  public boardRender(): void {
    this.ctx.fillStyle =
      localStorage.theme === 'dark' ? GAME.BOARD_COLOR_DARK : GAME.BOARD_COLOR
    this.game.clear()

    this.cells.reduce((_, { x, y, cellSize }) => {
      this.ctx.fillRect(x, y, cellSize, cellSize)
      return _
    }, {})

    if (!this.fruit) {
      this.fruit = this.getFruit()
    }

    this.fruitRender()
  }

  public fruitRender(): void {
    this.ctx.strokeStyle = localStorage.theme === 'dark' ? '#ffffff' : '#66824b'
    this.ctx.beginPath()
    this.ctx.arc(this.fruit.x, this.fruit.y, 1, 0, 2 * Math.PI)
    this.ctx.stroke()
  }

  public skipAnimation() {
    this.boardLoaded = true
    this.game.setProgress(100)
    this.boardRender()
  }
}
