import c from 'clsx'
import { Reorder, useDragControls, useMotionValue } from 'framer-motion'
import { IDetail, ISkill } from '../../../interfaces'
import {
  disableScroll,
  enableScroll
} from '../../../../../../../lib/functions/common'
import Icon from '../../../../../../organisms/Icon'
import ReorderItem from '../_shared/ReorderItem'
import NewItemButton from '../_shared/NewItemButton'

export default function Item({
  item,
  onSkillNameChange,
  openSkillId,
  handleOpenSkill,
  handleChangeDetail,
  handleRemoveDetail,
  handleAddDetail,
  handleDetailReorder,
  updateSkillsState,
  onRemoveItem,
  animation
}: {
  item: ISkill
  openSkillId: number
  handleOpenSkill: (id: number) => void
  handleChangeDetail: (skillId: number, detailId: number, value: string) => void
  handleRemoveDetail: (skillId: number, detailId: number) => void
  handleAddDetail: (skillId: number, value: string) => void
  handleDetailReorder: (skillsId: number, details: IDetail[]) => void
  onSkillNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  updateSkillsState: () => void
  onRemoveItem: () => void
  animation: boolean
}) {
  const y = useMotionValue(0)
  const dragControls = useDragControls()

  return (
    <Reorder.Item
      id={item.id.toString()}
      value={item}
      style={{ y }}
      dragListener={false}
      dragControls={dragControls}
      transition={animation ? {} : { duration: 0 }}
      onDragEnd={() => setTimeout(updateSkillsState, 300)}
    >
      <div className="flex">
        <div
          className={c(
            'bg-White dark:bg-dark-White flex items-center justify-between custom-no-select w-full',
            'border border-r-0 border-Grey px-2 py-1 transition-[border-radius]',
            openSkillId === item.id
              ? 'rounded-ss-md duration-300 ease-in-out'
              : 'rounded-l-md delay-300'
          )}
        >
          <div className="flex w-full">
            <div
              className="reorder-handle cursor-grab bg-gray-300 pr-1 text-lg"
              onPointerDown={event => dragControls.start(event)}
              onTouchMove={disableScroll}
              onTouchEnd={enableScroll}
            >
              ⠿
            </div>

            <input
              value={item.title}
              placeholder="Soft skills"
              className={c(
                'bg-transparent outline-none w-full ml-1 mr-2',
                'border-b text-Grey dark:text-LightBloom100op75',
                'focus:text-Primary focus:dark:text-dark-Primary',
                'placeholder:text-LightBloom100 placeholder:dark:text-LightBloom100op50'
              )}
              onChange={onSkillNameChange}
              onBlur={updateSkillsState}
            />
          </div>

          <button
            className={c(
              'text-nowrap text-White10 text-opacity-60 dark:text-LightBloom100 dark:text-opacity-50',
              'hover:bg-Grey10 hover:bg-opacity-25 dark:hover:bg-PrimaryLighter dark:hover:bg-opacity-25',
              'cursor-pointer p-1 rounded-md'
            )}
            onClick={() => handleOpenSkill(item.id)}
          >
            <Icon
              size={16}
              icon={openSkillId === item.id ? 'arrowUp' : 'arrowDown'}
            />
          </button>
        </div>

        <button
          className={c(
            'bg-White dark:bg-dark-White text-nowrap text-ErrorLight text-opacity-60 w-7',
            'hover:bg-ErrorLight hover:bg-opacity-25 dark:text-Error dark:text-opacity-50',
            'dark:hover:bg-Error dark:hover:bg-opacity-25 cursor-pointer rounded-r-md border px-1'
          )}
          onClick={onRemoveItem}
        >
          <Icon size={16} icon="remove" />
        </button>
      </div>

      <Reorder.Group
        axis="y"
        onReorder={details => handleDetailReorder(item.id, details)}
        values={item.details}
        className={c(
          'mb-2 mr-6.5 bg-White dark:bg-dark-White',
          'rounded-b-md border border-t-0 border-Grey',
          'overflow-hidden transition-all duration-500 ease-in-out',
          openSkillId === item.id
            ? 'opacity-100 py-3 pl-8 pr-2 lg:pl-10'
            : 'opacity-0'
        )}
        style={{
          height:
            openSkillId === item.id ? `${item.details.length * 28 + 52}px` : '0'
        }}
      >
        {item.details.map(detail => (
          <ReorderItem
            key={detail.id}
            item={detail}
            onChange={({ target }) =>
              handleChangeDetail(item.id, detail.id, target.value)
            }
            onRemove={() => handleRemoveDetail(item.id, detail.id)}
            onComplete={updateSkillsState}
            animation={animation}
          />
        ))}

        <NewItemButton
          onNewItemClick={value => {
            handleAddDetail(item.id, value)
          }}
          placeholder="Add new skill detail"
          scrollAnimation={false}
        />
      </Reorder.Group>
    </Reorder.Item>
  )
}
