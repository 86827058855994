import c from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { projects } from '../data'
import Stand from './Stand'

import { SCREEN as s } from './constants'

import {
  createGradientObject,
  getAnimationSymbols,
  getDisplayHeight
} from './functions'
import { ISymbol } from './interface'

interface DisplayProps {
  navActive: boolean
  currentProjectId: number
  children?: JSX.Element
}

const Display = ({
  navActive,
  currentProjectId
}: DisplayProps): JSX.Element => {
  const [displaySize, setDisplaySize] = useState(getDisplayHeight())
  const ctx = useRef<CanvasRenderingContext2D | null>(null)
  const symbols = useRef<ISymbol[] | null>(null)
  const gradient = useRef<CanvasGradient | null>(null)

  const startAnimation = () => {
    const canvas = document.getElementById('my-canvas') as HTMLCanvasElement
    ctx.current = canvas.getContext('2d') as CanvasRenderingContext2D

    ctx.current.font = s.FONT_SIZE + 'px monospace'

    if (ctx.current)
      gradient.current = createGradientObject(ctx.current, 'radial')

    // Run animation
    animate()
  }

  const stopAnimation = () => {
    ctx.current = null
    symbols.current = null
    gradient.current = null
  }

  const animate = () => {
    setTimeout(() => {
      if (ctx.current) {
        ctx.current.fillStyle =
          localStorage.theme === 'dark'
            ? 'rgba(0, 0, 0, 0.05)'
            : 'rgba(255, 255, 255, 0.05)'

        ctx.current.fillRect(0, 0, s.WIDTH, s.HEIGHT)
        ctx.current.fillStyle = gradient.current || ''

        symbols.current?.forEach((symbol: ISymbol) =>
          symbol.draw(ctx.current as CanvasRenderingContext2D)
        )

        requestAnimationFrame(animate)
      }
    }, s.FPS)
  }

  useEffect(() => {
    setDisplaySize(getDisplayHeight())
  }, [navActive])

  useEffect(() => {
    const resizeCanvasHeight = () => {
      setDisplaySize(getDisplayHeight())
    }
    symbols.current = getAnimationSymbols()

    startAnimation()
    window.addEventListener('resize', resizeCanvasHeight)

    return () => {
      window.removeEventListener('resize', resizeCanvasHeight)
      stopAnimation()
    }
  }, [])

  return (
    <>
      <div
        className="rounded-xl bg-displayBlack p-4 dark:bg-displayWhite transition-height duration-200"
        style={{ height: displaySize.height }}
      >
        <div className={c(['h-full w-full overflow-hidden'], ['relative'])}>
          {projects.map((project, index) => (
            <div
              key={project.id}
              className="w-full h-full bg-LightBloom100op75 absolute transition-transform duration-400 flex justify-center items-center"
              style={{
                transform: `translate(${
                  project.id === currentProjectId ? 0 : '110%'
                })`
              }}
            >
              <img
                key={project.id}
                src={project.mainImage}
                alt={`${index}_${project.icon}`}
                className="max-w-full m-auto"
              />
            </div>
          ))}

          <canvas
            id="my-canvas"
            height={s.HEIGHT}
            width={s.WIDTH}
            className="w-full h-full"
          ></canvas>
        </div>
      </div>
      <div className="opacity-70 dark:opacity-30">
        <div className="flex justify-center relative z-20 dark:opacity-80 dark:mt-0.5">
          <Stand displayHeight={displaySize.height} />
        </div>
        <div className="w-full h-10 bg-woodLight dark:bg-woodDark trapezoid relative z-10 -mt-6"></div>
      </div>
    </>
  )
}

export default Display
