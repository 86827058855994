import BoardService from './boardService'
import { GAME } from './constants'
import { EDirections, EKeyboard } from './interfaces'
import SnakeService from './snakeService'

export default class SnakeGame {
  private launched: boolean

  public ctx: CanvasRenderingContext2D
  public currentDirection: EDirections

  constructor(
    canvasContext: CanvasRenderingContext2D,
    public setProgress: React.Dispatch<React.SetStateAction<number>>,
    public setSnakeProgress: React.Dispatch<React.SetStateAction<number>>,
    public setGameOver: React.Dispatch<React.SetStateAction<boolean>>,
    public snakeService: SnakeService,
    public boardService: BoardService
  ) {
    this.ctx = canvasContext

    snakeService.setGameObj(this)
    boardService.setGameObj(this)
  }

  public async start(): Promise<void> {
    if (!this.ctx) return

    this.launched = true

    await this.boardService.boardRenderWithAnimation()
    // this.boardService.skipAnimation()

    await this.snakeService.snakeRenderWithAnimation()
    // this.snakeService.skipAnimation()

    window.addEventListener('keydown', this.keyDownEvents)

    this.render()
  }

  public stop(): void {
    this.launched = false

    this.setGameOver(true)

    window.removeEventListener('keydown', this.keyDownEvents)
  }

  private async render() {
    console.log()
  }

  // General
  public handleSkipBoard(): void {
    this.boardService.skipAnimation()
  }

  public handleSkipSnake(): void {
    this.snakeService.skipAnimation()
  }

  public keyDownEvents = (e: KeyboardEvent) => {
    if (
      e.code === EDirections.Left ||
      e.code === EDirections.Right ||
      e.code === EDirections.Down ||
      e.code === EDirections.Up
    ) {
      if (this.currentDirection) {
        this.currentDirection = e.code
      } else {
        this.currentDirection = e.code

        const callback = () => {
          this.boardService.boardRender()
          this.snakeService.snakeRender(this.currentDirection)
          this.animation(callback)
        }

        callback()
      }
    }

    if (e.code === EKeyboard.Space) {
      this.stop()
    }
  }

  public isEven(num: number) {
    return num & 1 ? false : true
  }

  public clear() {
    this.ctx.clearRect(0, 0, GAME.WIDTH, GAME.HEIGHT)
  }

  public animation(callback: FrameRequestCallback) {
    this.launched && requestAnimationFrame(callback)
  }
}
