import { calculateDistance } from '../../../../lib/functions/common'
import { GAME } from './constants'
import {
  EDirections,
  EOppositeDirections,
  IGameObject,
  ISnake,
  ISnakeDirection,
  ISnakePart
} from './interfaces'

export default class SnakeService {
  private ctx: CanvasRenderingContext2D
  private game: IGameObject

  private distance = 0
  private cellWithSpace = GAME.CELL_SIZE + GAME.CELL_SPACE_BETWEEN
  private halfCell = GAME.CELL_SIZE / 2
  private boardWidth = GAME.WIDTH - GAME.CELL_SPACE_BETWEEN
  private boardHeight = GAME.HEIGHT - GAME.CELL_SPACE_BETWEEN
  private boardMiddle = {
    x: 0,
    y: 0
  }
  public snakeLoaded: boolean
  private animationStep = 0
  private snakeAnimation: EDirections[]
  private snake: ISnake = {
    body: [
      {
        x: GAME.CELL_SIZE * 2.5 + GAME.CELL_SPACE_BETWEEN * 2,
        y: this.halfCell,
        direction: EDirections.Right
      },
      {
        x: GAME.CELL_SIZE * 1.5 + GAME.CELL_SPACE_BETWEEN,
        y: this.halfCell,
        direction: EDirections.Right
      },
      {
        x: this.halfCell,
        y: this.halfCell,
        direction: EDirections.Right
      }
    ],
    velocity: GAME.SNAKE_VELOCITY
  }
  private mouthSize = 0
  private maxMouthSize = this.halfCell - 4
  public currentDirection: EDirections = EDirections.Right

  public setGameObj(game: IGameObject) {
    this.ctx = game.ctx
    this.game = game

    this.ctx.lineWidth = GAME.CELL_SIZE
    this.ctx.lineCap = 'round'
    this.ctx.lineJoin = 'round'

    this.boardMiddle.x = GAME.WIDTH / this.cellWithSpace / 2 - 6
    this.boardMiddle.y = GAME.HEIGHT / this.cellWithSpace / 2 - 1
  }

  public snakeRenderWithAnimation(): Promise<void> {
    this.game.boardService.boardRender()
    this.snakeLoaded = false

    this.snakeAnimation = [
      ...new Array(3).fill(EDirections.Right),
      ...new Array(this.boardMiddle.y).fill(EDirections.Down),
      ...new Array(this.boardMiddle.x).fill(EDirections.Right)
    ]

    return new Promise(resolve => {
      const callback = () => {
        if (this.distance === this.cellWithSpace) {
          this.game.setSnakeProgress(
            Math.floor(
              (this.animationStep / (this.snakeAnimation.length - 1)) * 100
            )
          )

          this.animationStep++

          if (!this.snakeAnimation[this.animationStep]) {
            this.snakeLoaded = true
            this.snake.velocity = GAME.SNAKE_VELOCITY

            this.game.boardService.boardRender()

            // For Skip Button
            const snakeY =
              GAME.HEIGHT / 2 - this.halfCell - GAME.CELL_SPACE_BETWEEN
            const snakeX = (this.boardMiddle.x + 3) * this.cellWithSpace

            this.snake.body = [
              {
                x: snakeX + GAME.CELL_SIZE * 2.5 + GAME.CELL_SPACE_BETWEEN * 2,
                y: snakeY,
                direction: EDirections.Right
              },
              {
                x: snakeX + GAME.CELL_SIZE * 1.5 + GAME.CELL_SPACE_BETWEEN,
                y: snakeY,
                direction: EDirections.Right
              },
              {
                x: snakeX + this.halfCell,
                y: snakeY,
                direction: EDirections.Right
              }
            ]

            this.snake.body.reduce((_, snakePart, index) => {
              this.drawingSnake(snakePart, index)
              return _
            }, {})

            // Mouth
            this.drawMouth()

            // Eyes
            this.drawEyes()

            return resolve()
          }
        }

        this.game.boardService.boardRender()
        this.snakeRender(this.snakeAnimation[this.animationStep])

        this.game.animation(callback)
      }

      this.game.animation(callback)
    })
  }

  public snakeRender(currentDirection: ISnakeDirection): void {
    const cellMiddle = this.distance === this.cellWithSpace
    const isCellWithFruit = this.isCellWithFruit
    const currentHeadDirection = this.snake.body[0]
      .direction as EOppositeDirections

    // We can't choose opposite directions
    let next: ISnakeDirection
    if (currentDirection === EOppositeDirections[currentHeadDirection]) {
      next = currentHeadDirection
    } else {
      next = currentDirection
    }

    let current: ISnakeDirection

    if (cellMiddle) this.snakeCollision(next)

    // Fruit check
    if (cellMiddle && isCellWithFruit) this.eatingFruit()

    // Render
    this.snake.body.reduce((_, snakePart, index) => {
      if (cellMiddle) {
        current = this.snake.body[index].direction
        this.snake.body[index].direction = next

        next = current
      }
      this.drawingSnake(snakePart, index)
      this.handlePositionChange(snakePart, index)

      if (!index && cellMiddle) this.collision()

      return _
    }, {})

    // Mouth
    this.drawMouth()

    // Eyes
    this.drawEyes()

    // Distance
    if (cellMiddle) this.distance = 0
    this.distance += this.snake.velocity
  }

  public skipAnimation(): void {
    this.animationStep = this.snakeAnimation.length - 1
  }

  private drawMouth() {
    const head = this.snake.body[0]
    const fruit = this.game.boardService.fruit
    const distance = fruit
      ? calculateDistance(head.x, head.y, fruit.x, fruit.y)
      : 2

    let startingPoint = 0
    let endPoint = 0
    let x = 0
    let y = 0

    if (head.direction === EDirections.Up) {
      startingPoint = Math.PI
      endPoint = 0
      x = this.snake.body[0].x
      y = this.snake.body[0].y + 4
    }

    if (head.direction === EDirections.Right) {
      startingPoint = 1.5 * Math.PI
      endPoint = 0.5 * Math.PI
      x = this.snake.body[0].x - 4
      y = this.snake.body[0].y
    }

    if (head.direction === EDirections.Down) {
      startingPoint = 0
      endPoint = Math.PI
      x = this.snake.body[0].x
      y = this.snake.body[0].y - 4
    }

    if (head.direction === EDirections.Left) {
      startingPoint = 0.5 * Math.PI
      endPoint = 1.5 * Math.PI
      x = this.snake.body[0].x + 4
      y = this.snake.body[0].y
    }

    const percentLeft = (this.maxMouthSize * distance) / 120 / 100
    const newMouthSize = this.maxMouthSize * (1 - percentLeft)

    if (percentLeft > 1 || newMouthSize < this.mouthSize) {
      if (this.mouthSize < 0.1) {
        this.mouthSize = 0
      } else {
        this.mouthSize = this.mouthSize - 0.1
      }
    } else {
      this.mouthSize = newMouthSize
    }
    this.ctx.beginPath()

    this.ctx.arc(x, y, this.mouthSize, startingPoint, endPoint, false)
    this.ctx.fillStyle = GAME.SNAKE_MOUTH_COLOR
    this.ctx.fill()

    this.ctx.closePath()
  }

  private drawEyes() {
    const head = this.snake.body[0]
    const fruit = this.game.boardService.fruit

    let x1 = 0
    let y1 = 0
    let x2 = 0
    let y2 = 0

    if (head.direction === EDirections.Up) {
      x1 = this.snake.body[0].x - 10
      y1 = this.snake.body[0].y + 18
      x2 = this.snake.body[0].x + 10
      y2 = this.snake.body[0].y + 18
    }

    if (head.direction === EDirections.Right) {
      x1 = this.snake.body[0].x - 18
      y1 = this.snake.body[0].y - 10
      x2 = this.snake.body[0].x - 18
      y2 = this.snake.body[0].y + 10
    }

    if (head.direction === EDirections.Down) {
      x1 = this.snake.body[0].x - 10
      y1 = this.snake.body[0].y - 18
      x2 = this.snake.body[0].x + 10
      y2 = this.snake.body[0].y - 18
    }

    if (head.direction === EDirections.Left) {
      x1 = this.snake.body[0].x + 18
      y1 = this.snake.body[0].y - 10
      x2 = this.snake.body[0].x + 18
      y2 = this.snake.body[0].y + 10
    }

    this.ctx.beginPath()

    this.ctx.arc(x1, y1, 12, 0, 2 * Math.PI)
    this.ctx.arc(x2, y2, 12, 0, 2 * Math.PI)
    this.ctx.fillStyle = GAME.SNAKE_COLOR
    this.ctx.fill()
    this.ctx.closePath()

    this.ctx.beginPath()
    this.ctx.arc(x1, y1, 6, 0, 2 * Math.PI)
    this.ctx.arc(x2, y2, 6, 0, 2 * Math.PI)
    this.ctx.fillStyle = 'white'
    this.ctx.fill()

    this.ctx.beginPath()
    if (fruit) {
      const deltaX = fruit.x - x1
      const deltaY = fruit.y - y1
      const angleInRadians = Math.atan2(deltaY, deltaX)

      const xOnCircle = x1 + 4 * Math.cos(angleInRadians)
      const yOnCircle = y1 + 4 * Math.sin(angleInRadians)

      const xOnCircle2 = x2 + 4 * Math.cos(angleInRadians)
      const yOnCircle2 = y2 + 4 * Math.sin(angleInRadians)

      this.ctx.arc(xOnCircle, yOnCircle, 4, 0, 2 * Math.PI)
      this.ctx.arc(xOnCircle2, yOnCircle2, 4, 0, 2 * Math.PI)
    } else {
      this.ctx.arc(x1, y1, 4, 0, 2 * Math.PI)
      this.ctx.arc(x2, y2, 4, 0, 2 * Math.PI)
    }

    this.ctx.fillStyle = 'black'
    this.ctx.fill()

    this.ctx.closePath()
  }

  private eatingFruit() {
    const lastElement = this.snake.body[this.snake.body.length - 1]
    lastElement.direction = EDirections.Skip

    this.snake.body.push({
      x: lastElement.x,
      y: lastElement.y,
      direction: EDirections.Skip
    })

    this.game.boardService.fruit = null

    //------> Run eating animation
  }

  private handlePositionChange({ direction }: ISnakePart, index: number): void {
    if (direction === EDirections.Up) {
      this.snake.body[index].y -= this.snake.velocity
    }

    if (direction === EDirections.Right) {
      this.snake.body[index].x += this.snake.velocity
    }

    if (direction === EDirections.Down) {
      this.snake.body[index].y += this.snake.velocity
    }

    if (direction === EDirections.Left) {
      this.snake.body[index].x -= this.snake.velocity
    }
  }

  private drawingSnake({ x, y, direction }: ISnakePart, index: number): void {
    this.ctx.strokeStyle = GAME.SNAKE_COLOR

    // Head
    if (!index) {
      this.ctx.beginPath()
      this.ctx.moveTo(x, y)
    }

    if (
      this.snake.body[index + 1] &&
      this.snake.body[index + 1].direction !== this.snake.body[index].direction
    ) {
      this.ctx.lineTo(x, y)

      if (direction === EDirections.Down || direction === EDirections.Up) {
        this.ctx.lineTo(x, this.snake.body[index + 1].y)
      } else {
        this.ctx.lineTo(this.snake.body[index + 1].x, y)
      }
    } else {
      this.ctx.lineTo(x, y)
    }

    this.ctx.stroke()
  }

  private collision() {
    if (this.isBorderCollision) {
      this.game.stop()
    }
  }

  private snakeCollision(direction: ISnakeDirection) {
    const length = this.snake.body.length

    let x = this.snake.body[0].x
    let y = this.snake.body[0].y

    if (length > 4) {
      if (direction === EDirections.Up) {
        y -= this.cellWithSpace
      }

      if (direction === EDirections.Right) {
        x += this.cellWithSpace
      }

      if (direction === EDirections.Down) {
        y += this.cellWithSpace
      }

      if (direction === EDirections.Left) {
        x -= this.cellWithSpace
      }
    }

    for (let i = 2; i < length; i++) {
      if (x === this.snake.body[i].x && y === this.snake.body[i].y) {
        this.game.stop()
      }
    }
  }

  private get isBorderCollision() {
    return (
      this.snake.body[0].x - this.halfCell < 0 ||
      this.snake.body[0].x + this.halfCell > this.boardWidth ||
      this.snake.body[0].y - this.halfCell < 0 ||
      this.snake.body[0].y + this.halfCell > this.boardHeight
    )
  }

  private get isCellWithFruit() {
    return (
      this.snake.body[0].x === this.game.boardService.fruit.x &&
      this.snake.body[0].y === this.game.boardService.fruit.y
    )
  }
}
