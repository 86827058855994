import c from 'clsx'
import Icon from '../../../../../organisms/Icon'
import DownloadButton from './DownloadButton'
import SavePDFModal from './SavePDFModal/SavePDFModal'
import { useState } from 'react'
import { isSafari } from '../../../../../../lib/functions/common'
import { Action } from '../../actions'
import { State } from '../../interfaces'
import PDFDocument from '../../PdfDocument'

//COMPONENT
export default function Toolbar({
  refreshPdf,
  state,
  dispatch
}: {
  refreshPdf(): void
  state: State
  dispatch: React.Dispatch<Action>
}) {
  const { showToolbar, header } = state

  const [savePDFModalActive, setSavePDFModalActive] = useState(false)
  const buttonsStyles = c(
    'flex items-center pl-2 pr-1 py-1 gap-1',
    'cursor-pointer border rounded-md text-nowrap',
    'text-PrimaryLight10 dark:text-dark-PrimaryLight',
    'hover:bg-PrimaryLighter dark:hover:bg-dark-PrimaryLighter'
  )

  return (
    <>
      <div className="flex justify-between items-center p-2 overflow-auto">
        <button
          className={c(buttonsStyles, 'mr-2')}
          onClick={() => {
            setSavePDFModalActive(true)
          }}
        >
          Manage Resume
          <Icon
            size={18}
            icon="save"
            className="text-PrimaryLight10 dark:text-dark-PrimaryDark"
          />
        </button>

        <div className="flex gap-2">
          <DownloadButton
            documentName={header.fullName}
            document={<PDFDocument {...state} />}
          />

          <button
            className={c(buttonsStyles, isSafari() ? 'hidden' : '')}
            onClick={() => {
              refreshPdf()

              dispatch({
                type: 'CHANGE_TOOLBAR',
                payload: !showToolbar
              })
            }}
          >
            Show Toolbar
            <Icon
              size={18}
              icon={showToolbar ? 'visibility' : 'visibilityOff'}
              className={
                showToolbar
                  ? 'text-PrimaryLight10 dark:text-dark-PrimaryDark'
                  : 'text-LightBloom150 dark:text-LightBloom100op75'
              }
            />
          </button>
        </div>
      </div>

      <SavePDFModal
        state={state}
        dispatch={dispatch}
        active={savePDFModalActive}
        onClose={() => setSavePDFModalActive(false)}
        refreshPdf={refreshPdf}
      />
    </>
  )
}
